import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';

import { Voucher } from 'app-libs/components/campaign/types';
import { humanizeErrorMessageFromGoblin } from 'app-libs/etc/validateHelper';
import {
  getEmailRegisterAvailability,
  login,
  signup,
} from 'app-libs/redux_modules/auth';
import { setCustomerVouchers } from 'app-libs/redux_modules/entity_modules/voucher';
import { dontShowPromptOnError } from 'app-libs/redux_modules/flow_modules/notification';
import {
  trackClickedActionLogin,
  trackClickedActionSignUp,
  trackSuccessSignUp,
} from 'app-libs/trackers';

import { useMutationCreateWelcomeVoucher } from 'entities/voucher/utils';

import { K_SHOP_ASSISTANT_ROLE } from 'constants/userConstants';

import {
  K_FIELD_EMAIL,
  K_STATE_EMAIL_LOGIN,
  K_STATE_EMAIL_LOGIN_OR_REGISTER,
  K_STATE_EMAIL_REGISTER,
} from './constants';

const K_LOGIN_TYPE_ATTEMPT_LOGIN = 'attemptLogin';
const K_SIGN_UP_TYPE_ATTEMPT_SIGN_UP = 'attemptSignUp';

export const useEmailLoginOrRegisterHandler = () => {
  const dispatch = useDispatch();
  const [formState, setFormState] = useState(K_STATE_EMAIL_LOGIN_OR_REGISTER);
  const [formInitialValues, setFormInitialValues] = useState({
    [K_FIELD_EMAIL]: '',
  });
  const [goblinErrorMessage, setGoblinErrorMessage] = useState<string | null>(
    null,
  );
  const handleCreateWelcomeVoucherSuccess = useCallback(
    (res: Voucher) => {
      dispatch(setCustomerVouchers([res]));
    },
    [dispatch],
  );

  const createWelcomeVoucherMutation = useMutationCreateWelcomeVoucher({
    onSuccess: handleCreateWelcomeVoucherSuccess,
  });

  const handleEmailAvailability = useCallback(
    async (values, formikActions: TYPEFIXME) => {
      setGoblinErrorMessage(null);
      const { email } = values;
      trackClickedActionLogin(email, K_LOGIN_TYPE_ATTEMPT_LOGIN);

      const { result } = await dispatch(
        dontShowPromptOnError(getEmailRegisterAvailability(email.trim())),
      );

      if (result) {
        setFormInitialValues(values);
        setFormState(K_STATE_EMAIL_REGISTER);
      } else {
        setFormInitialValues(values);
        setFormState(K_STATE_EMAIL_LOGIN);
      }
      formikActions.setSubmitting(false);
    },
    [dispatch],
  );

  const handleEmailLogin = useCallback(
    async (values, formikActions: TYPEFIXME) => {
      setGoblinErrorMessage(null);
      const { email, password } = values;
      trackClickedActionLogin(email, K_LOGIN_TYPE_ATTEMPT_LOGIN);

      const { result, error } = await dispatch(
        dontShowPromptOnError(login(email.trim(), password)),
      );

      if (error) {
        setGoblinErrorMessage(error.response.body.error);
      } else if ((result?.groups ?? []).includes(K_SHOP_ASSISTANT_ROLE)) {
        // Force SA to reload to check in store
        setTimeout(() => window.location.reload(), 500);
      }
      formikActions.setSubmitting(false);
    },
    [dispatch],
  );

  const handleEmailRegister = useCallback(
    async (values, formikActions: TYPEFIXME) => {
      setGoblinErrorMessage(null);
      const { name: firstName, email, password } = values;
      trackClickedActionSignUp(email, K_SIGN_UP_TYPE_ATTEMPT_SIGN_UP);

      const signUpData = {
        firstName,
        email: email.trim(),
        password,
      };

      const { result } = await dispatch(
        dontShowPromptOnError(signup(signUpData)),
      );
      if (result?.error) {
        setGoblinErrorMessage(humanizeErrorMessageFromGoblin(result?.error));
      } else {
        createWelcomeVoucherMutation.mutate();
        trackSuccessSignUp(firstName, email, '');
      }

      formikActions.setSubmitting(false);
    },
    [createWelcomeVoucherMutation, dispatch],
  );
  return {
    formInitialValues,
    formState,
    goblinErrorMessage,
    handleEmailAvailability,
    handleEmailLogin,
    handleEmailRegister,
    setFormState,
  };
};
