import React, { useCallback } from 'react';

import { ScrollView, View } from '@dkrm/ui-kit-basic';
import { enhanceForm } from '@dkrm/ui-kit-basic/Form';
import { useAuth } from 'app-libs/hook_modules/auth';
import { useHistory } from 'app-libs/hook_modules/router';

import cStyles from '@dkrm/general-libs/theme/styles';

import { getLang } from 'app-libs/libs/getLang';
import { goBack } from '@dkrm/ui-kit-basic/Router';
import { NAV_CLOSE } from '@dkrm/ui-kit-basic/Icon/constants';
import NavbarTitleCentered from 'app-libs/components/NavbarImplementation/NavbarTitleCentered';
import CTAButton from '../components/CTAButton';
import EmailField from '../components/EmailField';
import { emailValidator } from '../components/validators';
import { K_FIELD_EMAIL } from '../components/constants';

interface ForgotPasswordFormProps {
  setFieldValue: (field: string, value: string) => void;
  handleSubmit: (values: TYPEFIXME) => void;
}

const ForgotPasswordForm: React.FC<ForgotPasswordFormProps> = (props) => {
  const { setFieldValue, handleSubmit } = props;
  const history = useHistory();

  const actionCloseForm = useCallback(() => {
    goBack(history);
  }, [history]);

  const { isLoggedIn, isForgettingPassword } = useAuth();

  if (isLoggedIn) return null;
  return (
    <>
      <NavbarTitleCentered
        containerStyle={cStyles.bgw}
        leftButtonIcon={NAV_CLOSE}
        leftButtonAction={actionCloseForm}
        title={getLang('authPage.forgotPasswordTitle')}
      />
      <ScrollView contentContainerStyle={[cStyles.pageMaxWidth, cStyles.paxl]}>
        <EmailField
          isDisabled={false}
          label={getLang('authPage.forgotPasswordPlaceholder')}
          setFieldValue={setFieldValue}
          handleSubmit={handleSubmit}
        />
        <View style={{ paddingVertical: 10 }} />
        <CTAButton
          title={
            isForgettingPassword
              ? getLang('authPage.resetPasswordOnProgress')
              : getLang('authPage.resetPassword')
          }
          onPress={handleSubmit}
          isDisabled={isForgettingPassword}
        />
      </ScrollView>
    </>
  );
};

const formConfig = {
  validator: {
    [K_FIELD_EMAIL]: emailValidator,
  },
};
export default enhanceForm(formConfig)(ForgotPasswordForm);
