import PropTypes from 'prop-types';
import React, { useState } from 'react';

import cStyles from '@dkrm/general-libs/theme/styles';
import { StyleSheet, View } from '@dkrm/ui-kit-basic';
import { NAV_CLOSE } from '@dkrm/ui-kit-basic/Icon/constants';
import colors from '@dkrm/ui-kit-basic/colors';
import { tStyles } from '@dkrm/ui-kit-basic/typography';
import { ButtonWithText } from '@dkrm/ui-kit-basic/v2';

import NavbarTitleCentered from 'app-libs/components/NavbarImplementation/NavbarTitleCentered';
import { getLang } from 'app-libs/libs/getLang';

const VerifyPhoneNumberPlatformSelect = ({
  platforms,
  onSelect,
  actionCloseForm,
}) => {
  const [selectedButton, setSelectedButton] = useState(null);

  const actionSelectPlatform = async (key) => {
    setSelectedButton(key);
    const isSuccessSelectPlatform = await onSelect(key);
    if (!isSuccessSelectPlatform) {
      setSelectedButton(null);
    }
  };

  return (
    <>
      <NavbarTitleCentered
        containerStyle={cStyles.bgw}
        leftButtonIcon={NAV_CLOSE}
        leftButtonAction={actionCloseForm}
        title={getLang('authPage.otpMethodSelection')}
      />
      <View style={s.contentContainer}>
        <View style={cStyles.pvxxl}>
          {platforms.map(({ key, name }) => (
            <View style={s.buttonContainer} key={key}>
              <ButtonWithText
                onPress={() => actionSelectPlatform(key)}
                theme="black"
                themeType="secondary"
                title={`Kirim melalui ${name}`}
                disabled={!!selectedButton}
                buttonStyle={s.button}
                textStyle={s.buttonText}
              />
            </View>
          ))}
        </View>
      </View>
    </>
  );
};

VerifyPhoneNumberPlatformSelect.propTypes = {
  platforms: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      key: PropTypes.string.isRequired,
    }),
  ).isRequired,
  onSelect: PropTypes.func.isRequired,
  actionCloseForm: PropTypes.func.isRequired,
};

const s = StyleSheet.create({
  buttonContainer: {
    ...cStyles.mbxl,
  },
  button: {
    ...cStyles.pvxl,
    borderColor: colors.C_BLACK_10,
    borderRadius: '8px',
  },
  buttonText: {
    ...tStyles.body2,
  },
  contentContainer: {
    ...cStyles.phxxl,
  },
});

export default VerifyPhoneNumberPlatformSelect;
