import React, { memo } from 'react';
import {
  TextInput as RNTextInput,
  TextInputProps as RNTextInputProps,
  TextStyle,
  ViewStyle,
} from 'react-native';

import cStyles from '@dkrm/general-libs/theme/styles';
import { StyleSheet, Text, View } from '@dkrm/ui-kit-basic';

import colorsV2 from 'app-libs/constants/colors';

import Icon from './Icon';

export type TextInputProps = RNTextInputProps & {
  label?: string;
  disabled?: boolean;
  errorMessage?: string | false | 0;
  prefix?: string;
  suffix?: string;
  style?: ViewStyle;
  labelStyle?: ViewStyle;
  labelTheme?: string;
  textInputContainerStyle?: ViewStyle;
  value?: string;
  numberOfLines?: number;
  multiline?: boolean;
  IconComponent?: (props: any) => JSX.Element;
  iconProps?: object;
  onIconPress?: () => void;
};

const noop = () => null;

const TextInput: React.FC<TextInputProps> = memo(
  ({
    label,
    prefix,
    suffix,
    disabled = false,
    errorMessage,
    style = null,
    labelStyle = null,
    labelTheme = 'body4-black60',
    textInputContainerStyle = null,
    IconComponent = null,
    iconProps = null,
    onIconPress = noop,
    ...rest
  }) => {
    const getTextInputContainerStyle = () => {
      if (disabled) return s.disabledTextInputContainer;
      if (errorMessage) return s.errorTextInputContainer;
      return s.textInputContainer;
    };

    return (
      <View style={[s.container, style]}>
        <Text theme={labelTheme} style={labelStyle}>
          {label}
        </Text>
        <View
          theme="body3"
          style={[getTextInputContainerStyle(), textInputContainerStyle]}
        >
          {prefix && (
            <Text theme="body3-black80" style={s.prefixText}>
              {prefix}
            </Text>
          )}
          <RNTextInput
            editable={!disabled}
            placeholderTextColor={colorsV2.C_BLACK_40}
            style={K_RN_TEXT_INPUT_STYLE}
            {...rest}
          />
          {suffix && (
            <Text theme="body3-black60" style={s.suffixText}>
              {suffix}
            </Text>
          )}
          {!suffix && IconComponent && (
            <Icon
              IconComponent={IconComponent}
              iconProps={iconProps}
              onIconPress={onIconPress}
            />
          )}
        </View>
        {!!errorMessage && (
          <Text theme="body4-red" style={s.errorMessage}>
            {errorMessage}
          </Text>
        )}
      </View>
    );
  },
);

const s = StyleSheet.create({
  container: {
    ...cStyles.pvm,
  },
  textInputContainer: {
    ...cStyles.mts,
    ...cStyles.bwam,
    ...cStyles.brm,
    ...cStyles.flexDirRow,
    ...cStyles.flexMiddleLeft,
    ...cStyles.width100,
    backgroundColor: colorsV2.C_LIGHT_N010,
    borderColor: colorsV2.C_LIGHT_N010,
  },
  errorTextInputContainer: {
    ...cStyles.mts,
    ...cStyles.bwam,
    ...cStyles.brm,
    ...cStyles.flexDirRow,
    ...cStyles.flexMiddleLeft,
    ...cStyles.width100,
    backgroundColor: colorsV2.C_LIGHT_N010,
    borderColor: colorsV2.C_RED_60,
  },
  prefixText: {
    ...cStyles.pal,
    ...cStyles.bwrm,
    borderTopLeftRadius: 8,
    borderBottomLeftRadius: 8,
    backgroundColor: colorsV2.C_LIGHT_N040,
    borderColor: colorsV2.C_BLACK_20,
  },
  suffixText: {
    ...cStyles.pal,
    ...cStyles.bwlm,
    borderTopRightRadius: 8,
    borderBottomRightRadius: 8,
    backgroundColor: colorsV2.C_LIGHT_N040,
    borderColor: colorsV2.C_BLACK_20,
  },
  disabledTextInputContainer: {
    ...cStyles.mts,
    ...cStyles.bwam,
    ...cStyles.brm,
    ...cStyles.flexDirRow,
    ...cStyles.flexMiddleLeft,
    ...cStyles.width100,
    backgroundColor: colorsV2.C_LIGHT_N040,
    borderColor: colorsV2.C_LIGHT_N040,
  },
  errorMessage: {
    ...cStyles.mts,
  },
});

// use direct style here, because somehow stylesheet are being overridden by scss.
const K_RN_TEXT_INPUT_STYLE: TextStyle = {
  padding: 12,
  width: '100%',
  borderWidth: 0,
  lineHeight: 20,
  fontSize: 14,
};

export default TextInput;
