import React, { useRef } from 'react';
import { useSelector } from 'react-redux';
import Swiper from 'react-native-web-swiper';

import { StyleSheet, View, ReflowView } from '@dkrm/ui-kit-basic';
import cStyles from '@dkrm/general-libs/theme/styles';

import colors from '@dkrm/ui-kit-basic/colors';
import { Banner } from 'app-libs/redux_modules/entity_modules/advertisement/types';
import { getAdsByLocation } from 'app-libs/redux_modules/selectors/advertisement';
import { getBannersFromAdvertisements } from 'app-libs/etc/advertisementHelper';

import { K__STOREFRONT_MWEB__LOGIN_WALL__BANNER } from 'constants/advertisementConstants';
import LoginWallSwiperItem, {
  K_BANNER_WIDTH_PROPORTION,
  K_BANNER_HEIGHT_PROPORTION,
} from './item';

const LoginWallSwiper: React.FC = () => {
  const swiperRef = useRef(null);
  const loginWallBannerAds = useSelector((state) =>
    getAdsByLocation(state, K__STOREFRONT_MWEB__LOGIN_WALL__BANNER),
  );

  let bannersToShow = new Array<Banner | null>(4).fill(null);
  if (loginWallBannerAds.length > 0) {
    bannersToShow = getBannersFromAdvertisements(loginWallBannerAds);
  }

  return (
    <View style={s.swiperContainer}>
      <ReflowView
        widthProportion={K_BANNER_WIDTH_PROPORTION}
        heightProportion={K_BANNER_HEIGHT_PROPORTION}
      >
        <Swiper
          ref={swiperRef}
          loop
          timeout={5}
          containerStyle={s.swiperContainer}
          springConfig={{ bounciness: 0 }}
          minDistanceToCapture={0}
          minDistanceForAction={0.1}
          controlsProps={{
            dotsPos: 'bottom-left',
            dotActiveStyle: { backgroundColor: colors.C_TEAL_500 },
            dotsWrapperStyle: s.pagination,
            prevTitle: '',
            nextTitle: '',
            dotsTouchable: true,
          }}
        >
          {bannersToShow.map((item, index) => (
            <LoginWallSwiperItem
              key={item?.imageUrl ?? `login-wall-item-${index}`}
              shouldPreloadSmallerVersion={index > 0}
              imageUrl={item?.imageUrl ?? ''}
              name={item?.name ?? ''}
            />
          ))}
        </Swiper>
      </ReflowView>
    </View>
  );
};

const s = StyleSheet.create({
  pagination: {
    ...cStyles.ptn,
    ...cStyles.mbn,
    marginLeft: -6,
    height: 10,
    bottom: 28,
    pointerEvents: 'none',
  },
  swiperContainer: {
    ...cStyles.width100,
  },
});

export default LoginWallSwiper;
