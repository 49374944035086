import { request } from 'graphql-request';
import superagent from 'superagent';

import config from 'config';

import { K_PRODUCT_DETAIL_VOUCHER_QUERY } from './constants';

export const getLoadProductVouchersQueryKey = (productUpc: string) => {
  return [`${productUpc}-vouchers`];
};

export const loadProductVouchers = async (
  productUpc: string,
  brand: string,
  category: string | null,
) => {
  const res = await request(
    config.API_URL_TINKERER,
    K_PRODUCT_DETAIL_VOUCHER_QUERY,
    {
      productUpc,
      brand,
      category,
    },
  );
  if (!res.error) return res.productVouchers;
  throw new Error('Terjadi Kesalahan');
};

export const loadVoucherByVoucherCode = async (voucherCode: string) => {
  const res = await superagent.get(
    `${config.API_URL_GOBLIN}/vouchers/${voucherCode}`,
  );

  if (res.error === false) return res.body;
  throw new Error('Terjadi Kesalahan');
};

export const createWelcomeVoucher = async () => {
  const res = await superagent
    .post(`${config.API_URL_GOBLIN}/vouchers/welcome-voucher/`)
    .withCredentials();

  return res.body;
};
