import isEmpty from 'lodash/isEmpty';
import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { actionRouteActionOpenURL } from '@dkrm/ui-kit-basic/Router';

import {
  getUrlWithOverridenQuery,
  getUrlWithQuery,
  getUrlWithoutProtocol,
} from 'app-libs/etc/routeHelper';
import { useAuth } from 'app-libs/hook_modules/auth';
import {
  useHistory,
  usePreviousProps,
  useQueries,
} from 'app-libs/hook_modules/router';
import {
  load as loadAuth,
  loadMarketplaceProfile,
} from 'app-libs/redux_modules/auth';
import { headBasketAndLoadIfExist } from 'app-libs/redux_modules/entity_modules/basket';
import { updateMemberParent } from 'app-libs/redux_modules/entity_modules/membership';
import {
  setShouldShowFailedGettingWelcomeVoucherModal,
  setShouldShowSuccessGettingWelcomeVoucherModal,
} from 'app-libs/redux_modules/entity_modules/voucher';
import { dontShowPromptOnError } from 'app-libs/redux_modules/flow_modules/notification';
import { getIsLoggedInFromSignUp } from 'app-libs/redux_modules/selectors/auth';
import { trackIdentifyUser, trackSuccessLogin } from 'app-libs/trackers';

export const useRedirectIsLoggedInEffect = () => {
  const { user, isAuthLoaded, isLoggedIn } = useAuth();
  const previousIsLoggedIn = usePreviousProps(isLoggedIn);
  const dispatch = useDispatch();
  const isLoggedInFromSignUp = useSelector(getIsLoggedInFromSignUp);
  const queries = useQueries();
  const history = useHistory();
  const { redirect, referralCode, claimVoucherCode } = queries;

  const handlePostLogin = useCallback(async () => {
    /*
      @note(dika) 18 Sept 2023: Logic related welcome voucher modal
      - Should show success getting welcome voucher if user comes from sign up page
      - Should show failed getting welcome voucher if user comes from logging in and see welcome voucher before
    */
    const shouldShowSuccessGettingWelcomeVoucherModal = isLoggedInFromSignUp;
    const shouldShowFailedGettingWelcomeVoucherModal =
      !isLoggedInFromSignUp && !!claimVoucherCode;
    const isRedirectedFromLogin =
      !shouldShowSuccessGettingWelcomeVoucherModal &&
      !shouldShowFailedGettingWelcomeVoucherModal;

    if (shouldShowSuccessGettingWelcomeVoucherModal) {
      dispatch(setShouldShowSuccessGettingWelcomeVoucherModal(true));
    }
    if (shouldShowFailedGettingWelcomeVoucherModal) {
      dispatch(setShouldShowFailedGettingWelcomeVoucherModal(true));
    }

    let shouldRedirectWithLinkStatus = false;
    if (referralCode) {
      if (isAuthLoaded && !isEmpty(user)) {
        const { email, uuid } = user;

        const { result, error } = await dispatch(
          updateMemberParent(uuid, email, referralCode),
        );

        if (error) {
          shouldRedirectWithLinkStatus = true;
        } else if (result) {
          const { status } = result;
          if (status !== 'ok') {
            shouldRedirectWithLinkStatus = true;
          }
        }
      }
    }
    if (redirect) {
      let redirectUrl = redirect;

      if (shouldRedirectWithLinkStatus) {
        redirectUrl = getUrlWithOverridenQuery(redirect, {
          linkStatus: 'fail',
        });
      }
      /** sanitize url protocol for security purpose
       * e.g. ?redirect=https://fake-dekoruma.com/logged-in
       * should redirect to dekoruma.com/fake-dekoruma.com/logged-in
       */
      const sanitizedRedirectTo = getUrlWithoutProtocol(redirectUrl);

      actionRouteActionOpenURL(
        getUrlWithQuery(sanitizedRedirectTo, {
          isRedirectedFromLogin,
        }),
        history,
        {
          isUsingAnchor: true,
        },
      );
    } else {
      actionRouteActionOpenURL(
        getUrlWithQuery('/', {
          isRedirectedFromLogin,
        }),
        history,
      );
    }
  }, [
    claimVoucherCode,
    dispatch,
    history,
    isAuthLoaded,
    isLoggedInFromSignUp,
    redirect,
    referralCode,
    user,
  ]);

  useEffect(() => {
    if (!isAuthLoaded) {
      dispatch(loadAuth());
    } else if (isAuthLoaded && !isEmpty(user)) {
      handlePostLogin();
    }
  }, [isAuthLoaded, dispatch, handlePostLogin, user]);

  useEffect(() => {
    if (!previousIsLoggedIn && isLoggedIn) {
      dispatch(dontShowPromptOnError(loadAuth()));
      dispatch(dontShowPromptOnError(loadMarketplaceProfile()));
      dispatch(dontShowPromptOnError(headBasketAndLoadIfExist()));
      trackSuccessLogin(user);
      trackIdentifyUser(user);
    }
  }, [dispatch, isLoggedIn, previousIsLoggedIn, user]);
};
