import React, { useMemo, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useSnackBar } from 'app-libs/hook_modules/snackBar';
import { useLoadAuthEffect } from 'app-libs/hook_modules/auth';
import { StyleSheet, View } from '@dkrm/ui-kit-basic';

import { forgotPassword } from 'app-libs/redux_modules/auth';
import { dontShowPromptOnError } from 'app-libs/redux_modules/flow_modules/notification';
import cStyles from '@dkrm/general-libs/theme/styles';

import { getLang } from 'app-libs/libs/getLang';
import ForgotPasswordForm from './ForgotPasswordForm';
import { K_FIELD_EMAIL } from '../components/constants';

const ForgotPasswordPage: React.FC = () => {
  const dispatch = useDispatch();
  const { showSnackBar } = useSnackBar();

  const formInitialValues = useMemo(() => {
    return {
      [K_FIELD_EMAIL]: '',
    };
  }, []);

  const actionForgotPassword = useCallback(
    async (values: TYPEFIXME) => {
      const email = values[K_FIELD_EMAIL];

      const { error } = await dispatch(
        dontShowPromptOnError(forgotPassword(email)),
      );
      if (error) {
        showSnackBar({
          message: getLang('authPage.forgotPasswordError'),
        });
      } else {
        showSnackBar({
          message: getLang('authPage.forgotPasswordConfirmation'),
        });
      }
    },
    [dispatch, showSnackBar],
  );

  useLoadAuthEffect();

  return (
    <View style={s.container}>
      <ForgotPasswordForm
        onSubmit={actionForgotPassword}
        initialValues={formInitialValues}
      />
    </View>
  );
};

const s = StyleSheet.create({
  container: {
    ...cStyles.pageMaxWidth,
    ...cStyles.pvn,
  },
});

export default ForgotPasswordPage;
