import React from 'react';
import { StyleSheet, TouchableNativeFeedbackOpacity } from '@dkrm/ui-kit-basic';
import cStyles from '@dkrm/general-libs/theme/styles';

interface IconProps {
  IconComponent: (props: any) => JSX.Element;
  iconProps?: object | null;
  onIconPress?: () => void;
}

const Icon: React.FC<IconProps> = (props) => {
  const { IconComponent, iconProps, onIconPress } = props;

  return (
    <TouchableNativeFeedbackOpacity
      onPress={onIconPress}
      containerStyle={s.iconContainer}
    >
      <IconComponent theme="black55" size={24} {...iconProps} />
    </TouchableNativeFeedbackOpacity>
  );
};

const s = StyleSheet.create({
  iconContainer: {
    ...cStyles.abs,
    ...cStyles.right,
    ...cStyles.pam,
  },
});

export default Icon;
