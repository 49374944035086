import React, { memo } from 'react';

import { getLang } from 'app-libs/libs/getLang';
import { K_ROUTE_LOGIN_PHONE_NUMBER } from 'constants/routeConstants';

import AbstractLoginButton from './AbstractLoginButton';

interface PhoneNumberLoginButtonProps {
  label?: string;
}

const PhoneNumberLoginButton: React.FC<PhoneNumberLoginButtonProps> = memo(
  ({ label = getLang('authPage.loginWithPhoneNumber') }) => (
    <AbstractLoginButton label={label} url={K_ROUTE_LOGIN_PHONE_NUMBER} />
  ),
);

export default PhoneNumberLoginButton;
