import {
  EmailValidator,
  StringValidator,
  PhoneNumberValidator,
} from '@dkrm/ui-kit-basic/Form/validators';

import { getLang } from 'app-libs/libs/getLang';
import { K_FIELD_EMAIL, K_FIELD_PASSWORD } from './constants';

export const emailOptionalValidator = EmailValidator({
  errorMessage: getLang('authPage.validator.emailInvalid'),
});

export const phoneNumberValidator = PhoneNumberValidator({
  errorMessage: getLang('authPage.validator.phoneNumberInvalid'),
})
  .required(getLang('authPage.validator.phoneNumberRequired'))
  .test(
    'check-phone-length',
    getLang('authPage.validator.phoneNumberInvalid'),
    (value: string | undefined) => {
      if (!value) {
        return true;
      }
      if (value.startsWith(getLang('commons.phoneNumberPrefix'))) {
        return value.length >= 10 && value.length <= 15;
      }
      if (value.startsWith('0')) {
        return value.length >= 10 && value.length <= 13;
      }
      return value.length >= 9 && value.length <= 12;
    },
  );

export const emailValidator = EmailValidator({
  errorMessage: getLang('authPage.validator.emailInvalid'),
}).required(getLang('authPage.validator.emailRequired'));

export const nameValidator = StringValidator()
  .required(getLang('authPage.validator.nameRequired'))
  .min(3, getLang('authPage.validator.nameTooShort'))
  .max(60, getLang('authPage.validator.nameTooLong'));

export const passwordLoginValidator = StringValidator().required(
  getLang('authPage.validator.passwordRequired'),
);

export const passwordRegisterValidator = StringValidator().when(
  [K_FIELD_EMAIL],
  {
    is: (email: string) => !!email,
    then: StringValidator()
      .min(6, getLang('authPage.validator.passwordMin6Char'))
      .required(getLang('authPage.validator.passwordRequired')),
  },
);

export const confirmPasswordRegisterValidator = StringValidator().when(
  [K_FIELD_PASSWORD],
  {
    is: (password: string) => !!password,
    then: StringValidator()
      .required(getLang('authPage.validator.passwordConfirmationRequired'))
      .test(
        'password-match',
        getLang('authPage.validator.passwordAndConfirmationPasswordNotMatch'),
        function checkValue(value: string) {
          // @note (Radit): use disable because 'this' is implicitly any type (which we cannot declare the type)
          // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
          // @ts-ignore
          const { password } = this.parent;
          return password === value;
        },
      ),
  },
);

export const passwordResetValidator = passwordRegisterValidator;
export const confirmPasswordResetValidator = confirmPasswordRegisterValidator;
