import forEach from 'lodash/forEach';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import capitalize from 'lodash/capitalize';
import { momentId, isSameDay } from 'app-libs/etc/dateHelper';

export function getCssClassIfFieldInvalid(field) {
  if (field && field.touched && field.error) {
    return 'invalid';
  }
  return '';
}

export function getCssClassIfFieldValidInvalid(field) {
  if (field && field.touched) {
    if (field.error) {
      return 'invalid';
    }
    return 'valid';
  }
  return '';
}

export function getComplainError(data) {
  if (!data.email || !data.orderKey || !data.orderNumber) {
    return 'Data pemesanan tidak valid.';
  }

  let quantity = 0;
  forEach(data.products, (product) => {
    quantity += product.quantity;
  });

  if (quantity === 0) {
    return 'Mohon masukkan barang yang ingin Anda kembalikan.';
  }

  if (!data.reason) {
    return 'Mohon masukkan alasan Anda ingin mengembalikan barang.';
  }

  if (!data.action) {
    return 'Mohon masukkan tindakan yang Anda inginkan jika permohonan diterima.';
  }

  if (!data.notes) {
    return 'Mohon masukkan penjelasan keluhan dalam masukan Catatan.';
  }

  if (data.images.length === 0) {
    return 'Mohon tambahkan gambar pendukung.';
  }

  return null;
}

export function validateShippingAddressData(data) {
  if (isEmpty(data)) return false;
  const errors = getErrorsDictShippingAddressData(data);
  if (isEmpty(errors)) return true;
  return false;
}

export function validateProjectAddressData(data) {
  if (isEmpty(data)) return false;
  const errors = getErrorsDictProjectAddressData(data);
  if (isEmpty(errors)) return true;
  return false;
}

/**
 * This function accept phone number WITHOUT internationalization (+62)
 */
export function getErrorHelperPhoneNumberWithoutInternationalizatonValid(
  phoneNumber,
) {
  if (!phoneNumber) return null;

  if (phoneNumber.length > 12) {
    return 'Nomor terlalu panjang, pastikan nomor yang Anda masukkan sudah benar.';
  }

  if (phoneNumber.startsWith('+62')) {
    return 'Hapus +62 di awal nomor';
  }
  if (phoneNumber.startsWith('62')) {
    return 'Hapus 62 di awal nomor';
  }
  if (phoneNumber.startsWith('1')) {
    return 'Kode area salah, awali nomor dengan 21 untuk wilayah Jabodetabek';
  }
  if (
    phoneNumber.startsWith('0') &&
    phoneNumber >= 2 &&
    !phoneNumber.startsWith('08')
  ) {
    return 'Mohon masukkan nomor no telepon tanpa diawali angka 0, contoh: +62 0214567890 menjadi +62 214567890';
  }
  if (phoneNumber.startsWith('08')) {
    return 'Mohon masukkan nomor HP tanpa diawali angka 0, contoh: +62 0812345678 menjadi +62 812345678';
  }
  if (phoneNumber.startsWith('0')) {
    return 'Hapus angka 0 di awal nomor, contoh: +62-21-3456-789';
  }
  if (phoneNumber && !validateInteger(stripPhoneNumber(phoneNumber))) {
    return 'No. Telepon harus angka';
  }
  if (phoneNumber.length < 9) {
    return 'Jumlah angka terlalu sedikit';
  }
  return null;
}

export function getErrorsDictShippingAddressData(data) {
  const errors = {};
  // generic validation
  if (!data.firstName) errors.firstName = 'Nama harus diisi';
  if (data.firstName && data.firstName.length > 30)
    errors.firstName = 'Nama maksimum 30 karakter';
  if (!data.guestEmail) errors.guestEmail = 'Email harus diisi';
  if (!data.phoneNumber) errors.phoneNumber = 'No. Telepon harus diisi';
  if (!data.line1) errors.line1 = 'Alamat harus diisi';
  if (!data.line3) errors.line3 = 'Kecamatan harus diisi';
  if (!data.line4) errors.line4 = 'Kota harus diisi';
  if (!data.state) errors.state = 'Provinsi harus diisi';
  if (!data.postcode) errors.postcode = 'Kode Pos harus diisi';

  // specific validation
  if (data.guestEmail && !validateEmail(data.guestEmail)) {
    errors.guestEmail = 'Email tidak valid';
  }

  if (data.phoneNumber && !data.phoneNumber.startsWith('+62')) {
    errors.phoneNumber = 'Mohon masukkan kode area sebelum no. telepon';
  }

  const errTextPhone = getErrorHelperPhoneNumberWithoutInternationalizatonValid(
    stripPhoneNumberInternationalization(data.phoneNumber),
  );
  if (data.phoneNumber && errTextPhone) {
    errors.phoneNumber = errTextPhone;
  }

  if (data.postcode && data.postcode === 5) {
    errors.postcode = 'Kode pos harus 5 digit';
  }
  if (data.postcode && !validateInteger(data.postcode)) {
    errors.postcode = 'Kode pos harus angka';
  }
  return errors;
}

export function getErrorsDictProjectAddressData(data) {
  const errors = {};
  // generic validation
  if (!data.line1) errors.line1 = 'Alamat harus diisi';
  if (!data.line3) errors.line3 = 'Kecamatan harus diisi';
  if (!data.line4) errors.line4 = 'Kota harus diisi';
  if (!data.state) errors.state = 'Provinsi harus diisi';

  return errors;
}

export function validateEmail(email) {
  const re = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;
  return re.test(email);
}

export function validateNumeric(n) {
  return !Number.isNaN(parseFloat(n)) && Number.isFinite(n);
}

export function validateInteger(n) {
  return /^\d+$/.test(n);
}

export function validatePositiveInteger(n) {
  return validateInteger(n) && parseInt(n, 10) > 0;
}

/**
 * Return phone number without +62
 * if cant find, return back input
 */
export function stripPhoneNumberInternationalization(phoneNumber) {
  if (!phoneNumber) return phoneNumber;
  if (phoneNumber.startsWith('+62')) {
    return phoneNumber.replace('+62', '');
  }
  return phoneNumber;
}

/**
 * Return phone number without "-" and whitespace
 */
export function stripPhoneNumber(phoneNumber) {
  if (!phoneNumber) return phoneNumber;

  let phone = phoneNumber;
  phone = phone.replace('-', '');
  phone = phone.replace(' ', '');

  return phone;
}

/**
 * Returning number type of a string
 * if a string is not a number format will return empty string
 */

export function castingStringToNumber(stringCast) {
  return !Number.isNaN(Number(stringCast)) ? Number(stringCast) : '';
}

/*
  Below for redux-form validation purpose.

  - How to used it in Component (That used redux-form connector)
  1. use createValidator function to define the rules for every field in redux-form component.
      example:
      const validationRules = createValidator({
        yesStatement: [required],
        promptText: [required]
      });
  2. in Component that use redux-form connector, set value of the property 'validate' with the variable validationRules
      example:
      @connectReduxForm({
        form: 'REDUX_FORM_ADDON_PRODUCT_FORM',
        fields: ['yesStatement', 'promptText'],
        validate: validationRules
      })

  - How to make a rule
  1. Rule is a function that accept only two parameters:
      a. 'value' of current field which is being validated
      b. 'values' => value of all field
  2. Rule must return nothing if value acceptable, otherwise return the invalid message.
 */

/**
 *  joinRulesValidator will return the first invalid message from rules that return invalid message
 */
const joinRulesValidator = (rules) => (value, data) =>
  rules.map((rule) => rule(value, data)).filter((error) => !!error)[0];

/**
 * required is rule function for redux-form
 */
export function required(value) {
  if (isEmpty(value) && typeof value !== 'number') {
    return 'Wajib diisi';
  }
  return undefined;
}

export function isValidNumber(value) {
  if (value && Number.isNaN(value)) {
    return 'Is not valid number.';
  }
  return undefined;
}

export function isValidPositiveInteger(value) {
  if (value && !validatePositiveInteger(value)) {
    return 'Is not a valid positive integer.';
  }
  return undefined;
}

export function emailFormat(value) {
  if (value && !validateEmail(value)) {
    return 'Email is not valid';
  }
  return undefined;
}

export function emailConfirmMatch(value, values) {
  if (value && values.email && value !== values.email) {
    return 'Email does not match';
  }
  return undefined;
}

export function passwordConfirmMatch(value, values) {
  if (value && values.password && value !== values.password) {
    return 'Password does not match';
  }
  return undefined;
}

export function password(value) {
  if (value && value.length < 6) {
    return 'Use 6 or more characters';
  }
  return undefined;
}

/**
 * How to use:
 *  normal: minLength(minLen)(value)
 *    eg. minLength(30)(firstName)
 *  with redux form validation: minLength(minLen),
 *   eg. [minLength(30), otherValidation]
 */
export function minLength(minLen) {
  return (value) => {
    if (!isEmpty(value) && value.length < minLen) {
      return `Must be at least ${minLen} characters`;
    }
    return undefined;
  };
}

export function maxLength(maxLen, errMessage = '') {
  return (value) => {
    if (!isEmpty(value) && value.length > maxLen) {
      return isEmpty(errMessage)
        ? `Must be no more than ${maxLen} characters`
        : errMessage;
    }
    return undefined;
  };
}

export function exactLength(len) {
  return (value) => {
    if (!isEmpty(value) && value.length !== len) {
      return `Harus tepat ${len} karakter`;
    }
    return undefined;
  };
}

// compare dateStart and dateEnd
export function dateEndNotBeforeDateStart(value, values) {
  if (
    value &&
    values &&
    values.dateEnd &&
    momentId(value).isBefore(values.dateStart)
  ) {
    if (isSameDay(value, values.dateStart)) {
      return 'Time end is before time start';
    }
    return 'Date end is before date start';
  }
  return undefined;
}

export function createValidator(rules) {
  return (data = {}) => {
    const errors = {};
    Object.keys(rules).forEach((key) => {
      const rule = joinRulesValidator([].concat(rules[key])); // concat enables both functions and arrays of functions
      const error = rule(data[key], data);
      if (error) {
        errors[key] = error;
      }
    });
    return errors;
  };
}

export function validateStateForm(data) {
  const errors = [];

  if (!data.status) errors.status = 'Status harus diisi';

  return errors;
}

export function humanizeErrorMessageFromGoblin(error) {
  const body = get(error, 'response.body');
  if (!body) {
    return 'Mohon coba lagi';
  }
  if (typeof body !== 'object') {
    return body;
  }

  return Object.entries(body)
    .map(([k, v]) => `${capitalize(k)}: ${v.join(', ')}`)
    .join(', ')
    .replace('NonFieldErrors: ', '')
    .replace('Nonfielderrors: ', '');
}

export function humanizeErrorMessage(error) {
  return humanizeErrorMessageFromGoblin(error);
}
