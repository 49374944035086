import React, { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';

import cStyles from '@dkrm/general-libs/theme/styles';
import { ScrollView, View } from '@dkrm/ui-kit-basic';
import { enhanceForm } from '@dkrm/ui-kit-basic/Form';
import { NAV_CLOSE } from '@dkrm/ui-kit-basic/Icon/constants';

import NavbarTitleCentered from 'app-libs/components/NavbarImplementation/NavbarTitleCentered';
import { getLang } from 'app-libs/libs/getLang';
import {
  isLoggedIn as getIsLoggedIn,
  isSigningUp as getIsSigningUp,
} from 'app-libs/redux_modules/selectors/auth';
import { trackViewedSignUpConfirmation } from 'app-libs/trackers';

import CTAButton from '../components/CTAButton';
import ClaimVoucherSection from '../components/ClaimVoucherSection';
import EmailField from '../components/EmailField';
import FormErrorMessage from '../components/FormErrorMessage';
import NameField from '../components/NameField';
import PhoneNumberField from '../components/PhoneNumberField';
import {
  emailOptionalValidator,
  nameValidator,
  phoneNumberValidator,
} from '../components/validators';

import {
  K_FIELD_EMAIL,
  K_FIELD_NAME,
  K_FIELD_PHONE_NUMBER,
  K_STATE_PHONE_NUMBER_LOGIN_OR_REGISTER,
} from './constants';
import { FormValues } from './types';

interface RegisterProps {
  handleSubmit: (values: FormValues, formikActions: TYPEFIXME) => void;
  isSubmitting: boolean;
  formErrorMessage: string;
  setFormState: (loginState: string) => void;
  setFieldValue: (field: string, value: string) => void;
}

const Register: React.FC<RegisterProps> = ({
  handleSubmit,
  isSubmitting,
  formErrorMessage,
  setFormState,
  setFieldValue,
}) => {
  const isLoggedIn: boolean = useSelector(getIsLoggedIn);
  const isSigningUp: boolean = useSelector(getIsSigningUp);
  const actionCloseForm = useCallback(() => {
    setFormState(K_STATE_PHONE_NUMBER_LOGIN_OR_REGISTER);
  }, [setFormState]);

  useEffect(() => {
    trackViewedSignUpConfirmation(getLang('authPage.signUpType.phoneNumber'));
  }, []);

  return (
    <>
      <NavbarTitleCentered
        containerStyle={cStyles.bgw}
        leftButtonIcon={NAV_CLOSE}
        leftButtonAction={actionCloseForm}
        title={getLang('authPage.continueRegistration')}
      />
      <ScrollView contentContainerStyle={[cStyles.pageMaxWidth, cStyles.paxl]}>
        <ClaimVoucherSection />
        <FormErrorMessage errorMessage={formErrorMessage} />
        <PhoneNumberField isDisabled setFieldValue={setFieldValue} />
        <NameField isDisabled={isSigningUp || isSubmitting || isLoggedIn} />
        <EmailField
          isDisabled={isSigningUp || isSubmitting || isLoggedIn}
          setFieldValue={setFieldValue}
          label={getLang('authPage.phoneNumber.registerForm.emailLabel')}
        />
        <View style={{ paddingVertical: 10 }} />
        <CTAButton
          title={
            isSubmitting || isSigningUp
              ? getLang('authPage.registerOnProgress')
              : getLang('authPage.register')
          }
          onPress={handleSubmit}
          isDisabled={isSigningUp || isSubmitting || isLoggedIn}
        />
      </ScrollView>
    </>
  );
};

const formConfig = {
  validator: {
    [K_FIELD_PHONE_NUMBER]: phoneNumberValidator,
    [K_FIELD_EMAIL]: emailOptionalValidator,
    [K_FIELD_NAME]: nameValidator,
  },
  enableReinitialize: true,
};

export default enhanceForm(formConfig)(Register);
