import React, { useCallback } from 'react';

import { Hide, Unhide } from '@dkrm/icons';
import { Field } from '@dkrm/ui-kit-basic/Form';

import TextInput from 'app-libs/components/TextInput';
import { useVisibility } from 'app-libs/hook_modules/visibility';
import { getLang } from 'app-libs/libs/getLang';

import { K_FIELD_CONFIRM_PASSWORD } from './constants';

interface ConfirmPasswordFieldProps {
  handleSubmit: Function;
  isDisabled: boolean;
}

const ConfirmPasswordField: React.FC<ConfirmPasswordFieldProps> = ({
  handleSubmit,
  isDisabled,
}) => {
  const [isPasswordVisible, showPassword, hidePassword] = useVisibility(false);

  const togglePasswordVisibility = useCallback(() => {
    if (isPasswordVisible) hidePassword();
    else showPassword();
  }, [isPasswordVisible, hidePassword, showPassword]);

  return (
    <Field name={K_FIELD_CONFIRM_PASSWORD}>
      {({
        field,
        form: { submitCount, errors, touched, setFieldValue },
      }: TYPEFIXME) => (
        <TextInput
          {...field}
          nativeID={K_FIELD_CONFIRM_PASSWORD}
          data-cy="confirm-password-field"
          name={K_FIELD_CONFIRM_PASSWORD}
          onChangeText={(text: string) =>
            setFieldValue(K_FIELD_CONFIRM_PASSWORD, text)
          }
          errorMessage={
            (submitCount || touched[K_FIELD_CONFIRM_PASSWORD]) &&
            errors[K_FIELD_CONFIRM_PASSWORD]
          }
          label={getLang('authPage.passwordConfirmation')}
          placeholder={getLang('authPage.passwordConfirmationPlaceholder')}
          autoComplete="off"
          secureTextEntry={!isPasswordVisible}
          IconComponent={isPasswordVisible ? Hide : Unhide}
          onIconPress={togglePasswordVisibility}
          onBlur={null}
          onPress={handleSubmit}
          onSubmitEditing={handleSubmit}
          disabled={isDisabled}
        />
      )}
    </Field>
  );
};

export default ConfirmPasswordField;
