import React, { memo } from 'react';

import cStyles from '@dkrm/general-libs/theme/styles';
import { HorizontalDivider, StyleSheet, View } from '@dkrm/ui-kit-basic';
import { NAV_CLOSE } from '@dkrm/ui-kit-basic/Icon/constants';
import { ModalEnhanced } from '@dkrm/ui-kit-basic/v2';

import FBLoginButton from 'app-libs/components/Auth/FBLoginButton/mobile';
import NavbarTitleCentered from 'app-libs/components/NavbarImplementation/NavbarTitleCentered';
import { getLang } from 'app-libs/libs/getLang';

import AppleLoginButton from 'components/AppleLoginButton';
import EmailLoginButton from 'components/LoginButton/EmailLoginButton';
import PhoneNumberLoginButton from 'components/LoginButton/PhoneNumberLoginButton';

interface LoginMethodModalProps {
  onCancel: () => void;
  shouldShowEmail?: boolean;
  shouldShowPhoneNumber?: boolean;
}

const LoginMethodModal: React.FC<LoginMethodModalProps> = memo(
  ({ onCancel, shouldShowEmail = false, shouldShowPhoneNumber = false }) => {
    return (
      <ModalEnhanced
        isVisible
        onBackdropPress={onCancel}
        style={s.modalContainer}
      >
        <NavbarTitleCentered
          containerStyle={s.whiteContainer}
          leftButtonIcon={NAV_CLOSE}
          leftButtonAction={onCancel}
          title={getLang('authPage.loginMethodModal.header')}
        />
        <HorizontalDivider color="#EDF0F0" borderWidth={1} />
        <View style={s.buttonContainer}>
          <FBLoginButton label={getLang('authPage.loginWithFacebook')} />
          <AppleLoginButton label={getLang('authPage.loginWithApple')} />
          {shouldShowEmail && (
            <EmailLoginButton label={getLang('authPage.loginWithEmail')} />
          )}
          {shouldShowPhoneNumber && (
            <PhoneNumberLoginButton
              label={getLang('authPage.loginWithPhoneNumber')}
            />
          )}
        </View>
      </ModalEnhanced>
    );
  },
);

const s = StyleSheet.create({
  modalContainer: {
    ...cStyles.pageMaxWidth,
    ...cStyles.bgw,
    margin: 0,
    justifyContent: 'flex-start',
  },
  buttonContainer: {
    ...cStyles.pvxxl,
    ...cStyles.phxl,
  },
  whiteContainer: {
    ...cStyles.bgw,
  },
});

export default LoginMethodModal;
