import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import has from 'lodash/has';

import { Text, View } from '@dkrm/ui-kit-basic';
import { ButtonWrapper as Button } from '@dkrm/ui-kit-basic/v2';
import { Facebook } from '@dkrm/icons';
import withRouter from '@dkrm/general-libs/Utils/withRouter';
import {
  isWebView,
  getPlatform,
} from '@dkrm/general-libs/Utils/userAgentDetector';

import {
  loginSocial,
  K_FACEBOOK_PERMISSIONS,
} from 'app-libs/redux_modules/auth';
import { FACEBOOK_SDK_URL } from 'config';
import * as authSelectors from 'app-libs/redux_modules/selectors/auth';

import { isNewerVersion } from 'app-libs/etc';

import wv from 'app-libs/libs/webView';
import { trackIdentifyUser, trackSignedInUser } from 'app-libs/trackers';

import styles from './styles';

const K_CONNECTED_STATUS = 'connected';
const K_IOS_FB_LOGIN_MINIMUM_APP_VERSION = '2.29';

@withRouter
@connect(
  (state) => ({
    user: authSelectors.getAuthUser(state),
    loggingInSocial: state.auth.loggingInSocial,
  }),
  {
    actionLoginSocial: loginSocial,
  },
)
export default class FBLoginButton extends Component {
  static propTypes = {
    label: PropTypes.string,
    actionFBLoginSuccess: PropTypes.func,
    actionFBLoginFail: PropTypes.func,

    user: PropTypes.shape.isRequired,
    loggingInSocial: PropTypes.bool.isRequired,
    actionLoginSocial: PropTypes.func.isRequired,
  };

  static defaultProps = {
    label: 'Lanjutkan dengan Facebook',
    actionFBLoginSuccess: () => {},
    actionFBLoginFail: () => {},
  };

  componentDidMount() {
    this._loadFBSDK();

    window.FB_LOGIN = this.actionLoginFacebookWithToken;
  }

  componentDidUpdate(prevProps) {
    const { user, loggingInSocial } = this.props;
    if (
      isEmpty(prevProps.user) &&
      !isEmpty(user) &&
      prevProps.loggingInSocial &&
      !loggingInSocial
    ) {
      trackIdentifyUser(user);
      setTimeout(() => {
        trackSignedInUser(user, true);
      }, 5000);
    }
  }

  statusChangeCallback = (response) => {
    const { actionFBLoginFail } = this.props;
    if (response.status === K_CONNECTED_STATUS) {
      const { accessToken } = response.authResponse;
      this.actionLoginFacebookWithToken(accessToken);
    } else {
      actionFBLoginFail();
    }
  };

  _loadFBSDK() {
    if (window.FB_SDK_IS_LOADED) return;

    const script = document.createElement('script');
    script.src = FACEBOOK_SDK_URL;
    script.type = 'text/javascript';
    script.async = true;
    script.addEventListener('load', () => {
      window.FB.Event.subscribe('auth.statusChange', this.statusChangeCallback);
    });
    document.body.appendChild(script);
    window.FB_SDK_IS_LOADED = true;
  }

  actionLoginFacebookWithToken = (token) => {
    const { actionLoginSocial, actionFBLoginSuccess } = this.props;

    actionLoginSocial(token, 'facebook');
    actionFBLoginSuccess();
  };

  actionLoginFacebook = () => {
    if (isWebView()) {
      wv.actionLoginWithFacebook();
    } else if (has(window, 'FB')) {
      window.FB.login(() => {}, { scope: K_FACEBOOK_PERMISSIONS.join(',') });
    }
  };

  render() {
    const { label, loggingInSocial } = this.props;
    const platform = getPlatform();
    const appVersion = __SERVER__ ? '' : window?.__APP_VERSION__ || '';
    const labelToShow = loggingInSocial ? 'Sedang masuk ...' : label;
    const buttonStyle = loggingInSocial
      ? styles.buttonDisabled
      : styles.buttonContainer;
    const facebookLogo = 'social/facebook-logo';
    const iconSize = 30;
    const iconTheme = 'white';
    // @note(dika) 8 Apr 2022: For ios, only show FB Login for version 2.30 or higher
    if (
      platform === 'ios' &&
      !isNewerVersion(K_IOS_FB_LOGIN_MINIMUM_APP_VERSION, appVersion)
    ) {
      return null;
    }
    return (
      <Button onPress={this.actionLoginFacebook} disabled={!!loggingInSocial}>
        {() => (
          <View style={buttonStyle}>
            <View style={styles.icon}>
              <Facebook name={facebookLogo} size={iconSize} theme={iconTheme} />
            </View>
            <Text style={styles.buttonTextTitle}>{labelToShow}</Text>
          </View>
        )}
      </Button>
    );
  }
}
