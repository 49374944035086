import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';

import cStyles from '@dkrm/general-libs/theme/styles';
import { ScrollView } from '@dkrm/ui-kit-basic';
import { enhanceForm } from '@dkrm/ui-kit-basic/Form';
import { NAV_CLOSE } from '@dkrm/ui-kit-basic/Icon/constants';

import NavbarTitleCentered from 'app-libs/components/NavbarImplementation/NavbarTitleCentered';
import { useVisibility } from 'app-libs/hook_modules/visibility';
import { getLang } from 'app-libs/libs/getLang';
import { isLoggedIn as getIsLoggedIn } from 'app-libs/redux_modules/selectors/auth';

import CTAButton from '../components/CTAButton';
import ClaimVoucherSection from '../components/ClaimVoucherSection';
import EmailField from '../components/EmailField';
import ForgotPassword from '../components/ForgotPassword';
import FormErrorMessage from '../components/FormErrorMessage';
import LoginMethodModal from '../components/LoginMethodModal';
import OtherMethod from '../components/OtherMethod';
import PasswordField from '../components/PasswordField';
import TnCSection from '../components/TnCSection';
import { K_FIELD_EMAIL, K_FIELD_PASSWORD } from '../components/constants';
import {
  emailValidator,
  passwordLoginValidator,
} from '../components/validators';

import { K_STATE_EMAIL_LOGIN_OR_REGISTER } from './constants';
import { FormValues } from './types';

interface LoginProps {
  handleSubmit(values: FormValues, formikActions: TYPEFIXME): void;
  isSubmitting: boolean;
  formErrorMessage: string | null;
  setFormState: (value: string) => void;
  setFieldValue: (field: string, value: string) => void;
}

const Login: React.FC<LoginProps> = ({
  handleSubmit,
  isSubmitting,
  formErrorMessage,
  setFormState,
  setFieldValue,
}) => {
  const [isModalVisible, showModal, hideModal] = useVisibility(false);

  const isLoggedIn: boolean = useSelector(getIsLoggedIn);

  const actionCloseForm = useCallback(() => {
    setFormState(K_STATE_EMAIL_LOGIN_OR_REGISTER);
  }, [setFormState]);

  return (
    <>
      <NavbarTitleCentered
        containerStyle={cStyles.bgw}
        leftButtonIcon={NAV_CLOSE}
        leftButtonAction={actionCloseForm}
        title={getLang('authPage.login')}
      />
      <ScrollView contentContainerStyle={[cStyles.pageMaxWidth, cStyles.paxl]}>
        <ClaimVoucherSection />
        <FormErrorMessage errorMessage={formErrorMessage} />
        <EmailField
          isDisabled
          onIconPress={actionCloseForm}
          setFieldValue={setFieldValue}
        />
        <PasswordField handleSubmit={handleSubmit} isDisabled={isSubmitting} />
        <ForgotPassword />
        <CTAButton
          title={
            isLoggedIn
              ? getLang('authPage.loginSuccess')
              : getLang('authPage.login')
          }
          onPress={handleSubmit}
          isDisabled={isSubmitting || isLoggedIn}
        />
        <OtherMethod onPress={showModal} />
        <TnCSection />
        {isModalVisible && (
          <LoginMethodModal onCancel={hideModal} shouldShowPhoneNumber />
        )}
      </ScrollView>
    </>
  );
};

const formConfig = {
  validator: {
    [K_FIELD_EMAIL]: emailValidator,
    [K_FIELD_PASSWORD]: passwordLoginValidator,
  },
  enableReinitialize: true,
};

export default enhanceForm(formConfig)(Login);
