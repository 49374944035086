import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';

import { login } from 'app-libs/redux_modules/auth';
import { dontShowPromptOnError } from 'app-libs/redux_modules/flow_modules/notification';
import { trackClickedActionLogin } from 'app-libs/trackers';

import { K_SHOP_ASSISTANT_ROLE } from 'constants/userConstants';

import { K_FIELD_EMAIL } from './constants';

const K_LOGIN_TYPE_ATTEMPT_LOGIN = 'attemptLogin';

const formInitialValues = {
  [K_FIELD_EMAIL]: '',
};

export const useEmailLoginOrRegisterHandler = () => {
  const dispatch = useDispatch();
  const [loginErrorMessage, setLoginErrorMessage] = useState<string | null>(
    null,
  );

  const handleEmailLogin = useCallback(
    async (values, formikActions: TYPEFIXME) => {
      setLoginErrorMessage(null);
      const { email, password } = values;
      trackClickedActionLogin(email, K_LOGIN_TYPE_ATTEMPT_LOGIN);

      const { result, error } = await dispatch(
        dontShowPromptOnError(login(email.trim(), password)),
      );

      if (error) {
        setLoginErrorMessage(error.response.body.error);
      } else if ((result?.groups ?? []).includes(K_SHOP_ASSISTANT_ROLE)) {
        // Force SA to reload to check in store
        setTimeout(() => window.location.reload(), 500);
      }
      formikActions.setSubmitting(false);
    },
    [dispatch],
  );

  return {
    formInitialValues,
    handleEmailLogin,
    loginErrorMessage,
  };
};
