import React, { useCallback } from 'react';

import { Hide, Unhide } from '@dkrm/icons';
import { Field } from '@dkrm/ui-kit-basic/Form';

import TextInput from 'app-libs/components/TextInput';
import { useVisibility } from 'app-libs/hook_modules/visibility';
import { getLang } from 'app-libs/libs/getLang';

import { K_FIELD_PASSWORD } from './constants';

const noop = () => null;

interface PasswordFieldProps {
  handleSubmit?: Function;
  isRegister?: boolean;
  isDisabled: boolean;
}

const PasswordField: React.FC<PasswordFieldProps> = ({
  handleSubmit = noop,
  isRegister = false,
  isDisabled,
}) => {
  const [isPasswordVisible, showPassword, hidePassword] = useVisibility(false);

  const togglePasswordVisibility = useCallback(() => {
    if (isPasswordVisible) hidePassword();
    else showPassword();
  }, [isPasswordVisible, hidePassword, showPassword]);

  const helperText = isRegister
    ? getLang('authPage.password.helperMessage')
    : null;

  return (
    <Field name={K_FIELD_PASSWORD}>
      {({
        field,
        form: { submitCount, errors, touched, setFieldValue },
      }: TYPEFIXME) => (
        <TextInput
          {...field}
          nativeID={K_FIELD_PASSWORD}
          autoFocus
          onChangeText={(text: string) => setFieldValue(K_FIELD_PASSWORD, text)}
          data-cy="password-field"
          label={getLang('authPage.password')}
          autoComplete="off"
          secureTextEntry={!isPasswordVisible}
          errorMessage={
            (submitCount || touched[K_FIELD_PASSWORD]) &&
            errors[K_FIELD_PASSWORD]
          }
          onBlur={null}
          onSubmitEditing={handleSubmit}
          IconComponent={isPasswordVisible ? Hide : Unhide}
          onIconPress={togglePasswordVisibility}
          placeholder={getLang('authPage.passwordPlaceholder')}
          helperText={helperText}
          disabled={isDisabled}
        />
      )}
    </Field>
  );
};

export default PasswordField;
