import React from 'react';

import { View, Text, StyleSheet } from '@dkrm/ui-kit-basic';

import { ErrorOutline } from '@dkrm/icons';

import cStyles from '@dkrm/general-libs/theme/styles';

interface FormErrorMessageProps {
  errorMessage?: string | null;
}

const FormErrorMessage: React.FC<FormErrorMessageProps> = ({
  errorMessage,
}) => {
  if (!errorMessage) {
    return null;
  }

  return (
    <View style={s.errorMessage}>
      <ErrorOutline theme="red" style={cStyles.mrs} />
      <Text theme="body3-red3">{errorMessage}</Text>
    </View>
  );
};

const s = StyleSheet.create({
  errorMessage: {
    ...cStyles.flexDirRow,
    ...cStyles.flexMiddleLeft,
    ...cStyles.pvs,
  },
});

export default FormErrorMessage;
