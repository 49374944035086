import React from 'react';
import { FCFetchData } from 'types';

import cStyles from '@dkrm/general-libs/theme/styles';
import { StyleSheet, View } from '@dkrm/ui-kit-basic';

import { useRedirectIsLoggedInEffect } from 'app-libs/hook_modules/redirectLogin';
import { load as loadAuth } from 'app-libs/redux_modules/auth';
import { isAuthLoaded } from 'app-libs/redux_modules/selectors/auth';

import Login from './Login';
import { useEmailLoginOrRegisterHandler } from './utils';

const LoginInternalPage: FCFetchData = () => {
  const { formInitialValues, handleEmailLogin, loginErrorMessage } =
    useEmailLoginOrRegisterHandler();

  useRedirectIsLoggedInEffect();

  return (
    <View style={s.container}>
      <Login
        onSubmit={handleEmailLogin}
        initialValues={formInitialValues}
        formErrorMessage={loginErrorMessage}
      />
    </View>
  );
};

const s = StyleSheet.create({
  container: {
    ...cStyles.pageMaxWidth,
    ...cStyles.pvn,
  },
});

LoginInternalPage.fetchData = ({ store }) => {
  if (!isAuthLoaded(store.getState())) {
    return store.dispatch(loadAuth());
  }
  return {};
};

export default LoginInternalPage;
