import React, { useCallback } from 'react';

import { Field } from '@dkrm/ui-kit-basic/Form';

import TextInput from 'app-libs/components/TextInput';
import { getLang } from 'app-libs/libs/getLang';

import { K_FIELD_PHONE_NUMBER } from './constants';

interface PhoneNumberFieldProps {
  handleSubmit?: Function;
  isDisabled: boolean;
  setFieldValue: (field: string, value: string) => void;
  isAutoFocus?: boolean;
}

const PhoneNumberField: React.FC<PhoneNumberFieldProps> = (props) => {
  const { handleSubmit, isDisabled, setFieldValue, isAutoFocus = true } = props;

  const transformPhoneNumberFieldToTextInputValue = useCallback(
    (text: string) => {
      // split the text to 4 characters per segment.
      // example input: '82299998999'
      // example output: '822 9999 8999'
      // source: https://stackoverflow.com/questions/6259515/how-can-i-split-a-string-into-segments-of-n-characters
      let cleanedText =
        text?.replace(getLang('commons.phoneNumberPrefix'), '') ?? '';
      if (cleanedText === '') return cleanedText;

      // remove non-numeric characters.
      // reason: somehow the non-numeric character is set although the setFieldValue is not called.
      // source: https://stackoverflow.com/questions/1862130/strip-all-non-numeric-characters-from-string-in-javascript
      cleanedText = cleanedText.replace(/\D/g, '');

      // add 0 in the front to simplify chunking
      if (!cleanedText.startsWith('0')) cleanedText = `0${cleanedText}`;
      const splitText = cleanedText.match(/.{1,4}/g) ?? [];
      cleanedText = splitText.join(' ');

      // remove the 0 to get the final result.
      cleanedText = cleanedText.substring(1);

      return cleanedText;
    },
    [],
  );

  const transformPhoneNumberTextInputToFieldValue = useCallback(
    (text: string) => {
      // source: https://stackoverflow.com/questions/6623231/remove-all-white-spaces-from-text
      return text.trim().replace(/\s/g, '');
    },
    [],
  );

  const handleChangeText = useCallback(
    (text: string) => {
      const transformedText = transformPhoneNumberTextInputToFieldValue(text);
      if (/^\+?[0-9]*$/.test(transformedText) || transformedText === '') {
        setFieldValue(K_FIELD_PHONE_NUMBER, transformedText);
      }
    },
    [setFieldValue, transformPhoneNumberTextInputToFieldValue],
  );

  return (
    <Field name={K_FIELD_PHONE_NUMBER}>
      {({ field, form: { submitCount, errors, touched } }: TYPEFIXME) => (
        <TextInput
          {...field}
          nativeID={K_FIELD_PHONE_NUMBER}
          autoFocus={isAutoFocus}
          errorMessage={
            (submitCount || touched[K_FIELD_PHONE_NUMBER]) &&
            errors[K_FIELD_PHONE_NUMBER]
          }
          autoCapitalize="none"
          value={transformPhoneNumberFieldToTextInputValue(field.value)}
          onBlur={null}
          onChangeText={handleChangeText}
          onSubmitEditing={handleSubmit}
          label={getLang('authPage.phoneNumber')}
          prefix={getLang('commons.phoneNumberPrefix')}
          placeholder={getLang('authPage.phoneNumberPlaceholder')}
          keyboardType="phone-pad"
          disabled={isDisabled}
        />
      )}
    </Field>
  );
};

export default PhoneNumberField;
