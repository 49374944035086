import React, { useCallback } from 'react';

import { ScrollView, View } from '@dkrm/ui-kit-basic';
import { enhanceForm } from '@dkrm/ui-kit-basic/Form';
import { useAuth } from 'app-libs/hook_modules/auth';
import { useHistory } from 'app-libs/hook_modules/router';

import cStyles from '@dkrm/general-libs/theme/styles';

import { getLang } from 'app-libs/libs/getLang';
import { goBack } from '@dkrm/ui-kit-basic/Router';
import { NAV_CLOSE } from '@dkrm/ui-kit-basic/Icon/constants';
import NavbarTitleCentered from 'app-libs/components/NavbarImplementation/NavbarTitleCentered';
import CTAButton from '../components/CTAButton';
import PasswordField from '../components/PasswordField';
import ConfirmPasswordField from '../components/ConfirmPasswordField';
import {
  passwordResetValidator,
  confirmPasswordResetValidator,
} from '../components/validators';
import {
  K_FIELD_PASSWORD,
  K_FIELD_CONFIRM_PASSWORD,
} from '../components/constants';

interface ResetPasswordFormProps {
  handleSubmit: (values: TYPEFIXME) => void;
  isSubmitting: boolean;
}

const ResetPasswordForm: React.FC<ResetPasswordFormProps> = (props) => {
  const { handleSubmit, isSubmitting } = props;
  const history = useHistory();

  const actionCloseForm = useCallback(() => {
    goBack(history);
  }, [history]);

  const { isLoggedIn } = useAuth();

  return (
    <>
      <NavbarTitleCentered
        containerStyle={cStyles.bgw}
        leftButtonIcon={NAV_CLOSE}
        leftButtonAction={actionCloseForm}
        title={getLang('passwordReset.title')}
      />
      <ScrollView contentContainerStyle={[cStyles.pageMaxWidth, cStyles.paxl]}>
        <PasswordField isDisabled={isSubmitting || isLoggedIn} isRegister />
        <ConfirmPasswordField
          handleSubmit={handleSubmit}
          isDisabled={isSubmitting || isLoggedIn}
        />
        <View style={{ paddingVertical: 10 }} />
        <CTAButton
          title={
            isSubmitting
              ? getLang('authPage.resetPasswordOnProgress')
              : getLang('authPage.resetPassword')
          }
          onPress={handleSubmit}
          isDisabled={isSubmitting}
        />
      </ScrollView>
    </>
  );
};

const formConfig = {
  validator: {
    [K_FIELD_PASSWORD]: passwordResetValidator,
    [K_FIELD_CONFIRM_PASSWORD]: confirmPasswordResetValidator,
  },
};
export default enhanceForm(formConfig)(ResetPasswordForm);
