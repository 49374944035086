import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';

import { requestVerificationOTPToken } from 'app-libs/redux_modules/auth';
import { dontShowPromptOnError } from 'app-libs/redux_modules/flow_modules/notification';
import { trackVerifyOTPSuccess } from 'app-libs/trackers';

import { ViewStyle } from 'types/styles';

import CaptchaInput from './CaptchaInput';
import VerifyPhoneNumberConfirmation from './VerifyPhoneNumberConfirmation';
import VerifyPhoneNumberPlatformSelect from './VerifyPhoneNumberPlatformSelect';
import { K_AVAILABLE_PLATFORMS } from './constants';

const K_FAILED_VERIFIED_OTP_STATUS = 'failed';
const K_SUCCESS_VERIFIED_OTP_STATUS = 'ok';

interface Props {
  phoneNumber: string;
  onCloseForm(): void;
  onSuccessVerifyOTP?(result: object): void;
  onVerifyOTP(phoneNumber: string, otpCode: string): void;
  style: ViewStyle;
}

const OTPVerification: React.FC<Props> = ({
  phoneNumber,
  onCloseForm,
  onSuccessVerifyOTP = () => null,
  onVerifyOTP,
}) => {
  const dispatch = useDispatch();

  const [errorMessage, setErrorMessage] = useState('');
  const [shouldShowCaptcha, setShouldShowCaptcha] = useState(false);
  const [platform, setPlatform] = useState('');

  const actionVerifyOTP = useCallback(
    async (otpCode: string) => {
      setErrorMessage('');
      const { result, error } = await dispatch(
        dontShowPromptOnError(onVerifyOTP(phoneNumber, otpCode)),
      );
      if (result) {
        onSuccessVerifyOTP(result);
        trackVerifyOTPSuccess(phoneNumber, platform);
        return K_SUCCESS_VERIFIED_OTP_STATUS;
      }

      const { status: statusCode } = error;
      if (statusCode === 400) {
        setErrorMessage('Kode OTP salah atau kedaluwarsa');
      } else {
        setErrorMessage('Terjadi kesalahan, silakan coba lagi');
      }
      return K_FAILED_VERIFIED_OTP_STATUS;
    },
    [
      dispatch,
      onSuccessVerifyOTP,
      setErrorMessage,
      onVerifyOTP,
      phoneNumber,
      platform,
    ],
  );
  const actionRequestOTP = useCallback(
    async (selectedPlatform?: string, captchaInput = '') => {
      setErrorMessage('');
      const { error } = await dispatch(
        dontShowPromptOnError(
          requestVerificationOTPToken(
            phoneNumber,
            selectedPlatform || platform,
            captchaInput,
          ),
        ),
      );

      if (error) {
        const {
          status: statusCode,
          response: {
            body: { code: errorCode, error: errorMsg },
          },
        } = error;
        if (errorCode === 'CAPTCHA_ERROR') {
          setErrorMessage(errorMsg);
          setShouldShowCaptcha(true);
          return error;
        }
        if (statusCode !== 403) {
          setErrorMessage('Terjadi kesalahan, silakan coba lagi');
          return error;
        }
      } else {
        setShouldShowCaptcha(false);
        setErrorMessage('');
      }
      return error;
    },
    [dispatch, phoneNumber, platform],
  );

  const actionSelectPlatform = async (selectedPlatform: string) => {
    const requestError = await actionRequestOTP(selectedPlatform);
    setPlatform(selectedPlatform);
    if (!requestError) {
      return true;
    }
    return false;
  };

  const actionCloseForm = useCallback(() => {
    if (platform) {
      setPlatform('');
      setErrorMessage('');
    } else {
      onCloseForm();
    }
  }, [onCloseForm, platform, setPlatform]);
  return (
    <>
      {!!shouldShowCaptcha && (
        <CaptchaInput
          actionRequestOTP={(captchaInput) =>
            actionRequestOTP(platform, captchaInput)
          }
          actionCloseForm={actionCloseForm}
        />
      )}

      {!shouldShowCaptcha && !!platform && (
        <VerifyPhoneNumberConfirmation
          phoneNumber={phoneNumber}
          platform={platform}
          actionRequestOTP={actionRequestOTP}
          errorMessage={errorMessage}
          actionVerifyOTP={actionVerifyOTP}
          actionCloseForm={actionCloseForm}
        />
      )}
      {!shouldShowCaptcha && !platform && (
        <VerifyPhoneNumberPlatformSelect
          phoneNumber={phoneNumber}
          platforms={K_AVAILABLE_PLATFORMS}
          onSelect={actionSelectPlatform}
          errorMessage={errorMessage}
          actionCloseForm={actionCloseForm}
        />
      )}
    </>
  );
};

export default OTPVerification;
