import React, { useEffect } from 'react';
import { FCFetchData } from 'types';
import { StyleSheet, View } from '@dkrm/ui-kit-basic';
import cStyles from '@dkrm/general-libs/theme/styles';

import { load as loadAuth } from 'app-libs/redux_modules/auth';
import { isAuthLoaded } from 'app-libs/redux_modules/selectors/auth';
import { trackViewedLoginWallPage } from 'app-libs/trackers';
import { useRedirectIsLoggedInEffect } from 'app-libs/hook_modules/redirectLogin';
import LoginWallSwiper from './LoginWallSwiper';
import LoginOrRegister from './LoginOrRegister';
import { usePhoneNumberLoginWallHandler } from './utils';

const LoginWallPage: FCFetchData = () => {
  const {
    goblinErrorMessage,
    handlePhoneNumberAvailability,
  } = usePhoneNumberLoginWallHandler();

  useRedirectIsLoggedInEffect();
  useEffect(() => {
    trackViewedLoginWallPage();
  }, []);

  return (
    <View style={s.container}>
      <LoginWallSwiper />
      <View style={[cStyles.phxl, cStyles.pvxxl]}>
        <LoginOrRegister
          onSubmit={handlePhoneNumberAvailability}
          formErrorMessage={goblinErrorMessage}
        />
      </View>
    </View>
  );
};

const s = StyleSheet.create({
  container: {
    ...cStyles.pageMaxWidth,
    ...cStyles.pvn,
  },
});

LoginWallPage.fetchData = ({ store }) => {
  if (!isAuthLoaded(store.getState())) {
    return store.dispatch(loadAuth());
  }
  return {};
};

export default LoginWallPage;
