import React from 'react';
import { StyleSheet } from '@dkrm/ui-kit-basic';
import cStyles from '@dkrm/general-libs/theme/styles';
import { ButtonWithText } from '@dkrm/ui-kit-basic/v2';
import colorsV2 from 'app-libs/constants/colors';

interface CTAButtonProps {
  title: string;
  onPress: (values: any, formikActions: TYPEFIXME) => void;
  isDisabled?: boolean;
}
const CTAButton: React.FC<CTAButtonProps> = ({
  title,
  onPress,
  isDisabled = false,
}) => {
  return (
    <ButtonWithText
      title={title}
      buttonStyle={s.button}
      themeType="primary"
      onPress={onPress}
      disabled={isDisabled}
    />
  );
};

const s = StyleSheet.create({
  button: {
    ...cStyles.brm,
    backgroundColor: colorsV2.C_PRIMARY_TEAL,
  },
});

export default CTAButton;
