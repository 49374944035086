import React, { memo } from 'react';

import { getLang } from 'app-libs/libs/getLang';
import { K_ROUTE_LOGIN_EMAIL } from 'constants/routeConstants';

import AbstractLoginButton from './AbstractLoginButton';

interface EmailLoginButtonProps {
  label?: string;
}

const EmailLoginButton: React.FC<EmailLoginButtonProps> = memo(
  ({ label = getLang('authPage.loginWithEmail') }) => (
    <AbstractLoginButton label={label} url={K_ROUTE_LOGIN_EMAIL} />
  ),
);

export default EmailLoginButton;
