import React, { useEffect, useMemo } from 'react';
import { FCFetchData } from 'types';

import cStyles from '@dkrm/general-libs/theme/styles';
import { StyleSheet, View } from '@dkrm/ui-kit-basic';

import { useRedirectIsLoggedInEffect } from 'app-libs/hook_modules/redirectLogin';
import { load as loadAuth } from 'app-libs/redux_modules/auth';
import { isAuthLoaded } from 'app-libs/redux_modules/selectors/auth';
import { trackViewedLoginOrRegisterEmailPage } from 'app-libs/trackers';

import Login from './Login';
import LoginOrRegister from './LoginOrRegister';
import Register from './Register';
import {
  K_STATE_EMAIL_LOGIN,
  K_STATE_EMAIL_LOGIN_OR_REGISTER,
  K_STATE_EMAIL_REGISTER,
} from './constants';
import { useEmailLoginOrRegisterHandler } from './utils';

const K_COMPONENT_BY_STATE: Record<string, React.FC<any>> = {
  [K_STATE_EMAIL_LOGIN_OR_REGISTER]: LoginOrRegister,
  [K_STATE_EMAIL_LOGIN]: Login,
  [K_STATE_EMAIL_REGISTER]: Register,
};

const LoginOrRegisterEmailPage: FCFetchData = () => {
  const {
    formInitialValues,
    formState,
    handleEmailRegister,
    handleEmailLogin,
    handleEmailAvailability,
    goblinErrorMessage,
    setFormState,
  } = useEmailLoginOrRegisterHandler();

  useRedirectIsLoggedInEffect();
  useEffect(() => {
    trackViewedLoginOrRegisterEmailPage();
  }, []);

  const ActiveComponent = K_COMPONENT_BY_STATE[formState];

  /*
   * @note(Radit): the onSubmit here is actually for formik use.
   * so in the Component it will be pass as `handleSubmit` instead of `onSubmit` props.
   */
  const propsByState: Record<string, object> = useMemo(
    () => ({
      [K_STATE_EMAIL_LOGIN_OR_REGISTER]: {
        onSubmit: handleEmailAvailability,
        initialValues: formInitialValues,
        formErrorMessage: goblinErrorMessage,
        setFormState,
      },
      [K_STATE_EMAIL_LOGIN]: {
        onSubmit: handleEmailLogin,
        initialValues: formInitialValues,
        formErrorMessage: goblinErrorMessage,
        setFormState,
      },
      [K_STATE_EMAIL_REGISTER]: {
        onSubmit: handleEmailRegister,
        initialValues: formInitialValues,
        formErrorMessage: goblinErrorMessage,
        setFormState,
      },
    }),
    [
      goblinErrorMessage,
      handleEmailAvailability,
      handleEmailLogin,
      handleEmailRegister,
      formInitialValues,
      setFormState,
    ],
  );

  const activeProps = useMemo(
    () => propsByState[formState],
    [propsByState, formState],
  );

  return (
    <View style={s.container}>
      <ActiveComponent {...activeProps} />
    </View>
  );
};

const s = StyleSheet.create({
  container: {
    ...cStyles.pageMaxWidth,
    ...cStyles.pvn,
  },
});

LoginOrRegisterEmailPage.fetchData = ({ store }) => {
  if (!isAuthLoaded(store.getState())) {
    return store.dispatch(loadAuth());
  }
  return {};
};

export default LoginOrRegisterEmailPage;
