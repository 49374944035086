import React, { memo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import cStyles from '@dkrm/general-libs/theme/styles';
import { Colors, StyleSheet, Text, View } from '@dkrm/ui-kit-basic';

import { Voucher as IVoucher } from 'app-libs/components/campaign/types';
import { useQueries } from 'app-libs/hook_modules/router';
import {
  isCustomerVoucherLoaded as getIsCustomerVoucherLoaded,
  getNewCustomerVouchers,
} from 'app-libs/redux_modules/entity_modules/selectors/voucher';
import { loadCustomerVouchers } from 'app-libs/redux_modules/entity_modules/voucher';
import { isLoggedIn as getIsLoggedIn } from 'app-libs/redux_modules/selectors/auth';

import Voucher from './Voucher';

const ClaimVoucherSection: React.FC = memo(() => {
  const { claimVoucherCode = '' } = useQueries();
  const dispatch = useDispatch();
  const isLoggedIn = useSelector(getIsLoggedIn);
  const newCustomerVouchers: IVoucher[] = useSelector(getNewCustomerVouchers);
  const isCustomerVoucherLoaded: boolean = useSelector(
    getIsCustomerVoucherLoaded,
  );

  useEffect(() => {
    if (!isCustomerVoucherLoaded && !isLoggedIn) {
      dispatch(loadCustomerVouchers());
    }
  }, [dispatch, isCustomerVoucherLoaded, isLoggedIn]);

  const selectedNewCustomerVoucher = newCustomerVouchers.find(
    (voucher: IVoucher) => voucher.code === claimVoucherCode,
  );

  if (isLoggedIn || !selectedNewCustomerVoucher) return null;

  return (
    <View style={s.container}>
      <Text theme="h4">
        Selangkah Lagi Untuk Klaim Vouchermu. Daftarkan Dirimu Sekarang!
      </Text>
      <Voucher voucher={selectedNewCustomerVoucher} />
    </View>
  );
});

const s = StyleSheet.create({
  container: {
    margin: -16,
    ...cStyles.mbn,
    ...cStyles.paxl,
    backgroundColor: Colors.C_NEUTRAL_10,
  },
});

export default ClaimVoucherSection;
