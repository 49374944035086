import { StyleSheet } from 'react-native';
import cStyles from '@dkrm/general-libs/theme/styles';
import colors from '@dkrm/ui-kit-basic/colors';

export default StyleSheet.create({
  buttonContainer: {
    flex: 1,
    flexDirection: 'row',
    minHeight: 50,
    maxHeight: 50,
    minWidth: 88,
    justifyContent: 'center',
    alignItems: 'center',
    paddingHorizontal: 20,
    backgroundColor: colors.C_BLUE_FACEBOOK,
    borderRadius: 4,
  },
  buttonDisabled: {
    flex: 1,
    flexDirection: 'row',
    minHeight: 50,
    maxHeight: 50,
    minWidth: 88,
    justifyContent: 'center',
    alignItems: 'center',
    paddingHorizontal: 20,
    backgroundColor: colors.C_BLUE_FACEBOOK,
    opacity: 0.3,
    borderRadius: 4,
  },
  active: {
    backgroundColor: colors.C_SECONDARY_TOSCA_1,
  },
  buttonTextTitle: {
    fontSize: 15,
    color: colors.C_WHITE_100,
  },
  icon: {
    ...cStyles.prxl,
  },
});
