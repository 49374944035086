import React, { memo, useCallback, useState } from 'react';

import config from 'config';

import cStyles from '@dkrm/general-libs/theme/styles';
import { NAV_CLOSE } from '@dkrm/ui-kit-basic/Icon/constants';

import CaptchaVerification from 'app-libs/components/CaptchaVerification';
import NavbarTitleCentered from 'app-libs/components/NavbarImplementation/NavbarTitleCentered';
import { getLang } from 'app-libs/libs/getLang';

import { genRandomSeed } from './utils';

interface CaptchaInputProps {
  actionCloseForm: () => void;
  actionRequestOTP: (
    selectedPlatform?: string,
    captchaInput?: string,
  ) => Promise<{}>;
}

const CaptchaInput: React.FC<CaptchaInputProps> = memo(
  ({ actionRequestOTP, actionCloseForm }) => {
    const [isRequestingOTP, setIsRequestingOTP] = useState(false);
    const [captchaInput, setCaptchaInput] = useState('');

    const captchaUrlTemplate = `${config.API_URL_SURTUR_V2}/otp-captcha/?seed=`;
    const [captchaUrl, setCaptchaUrl] = useState(
      `${captchaUrlTemplate}${genRandomSeed()}`,
    );

    const actionResetCaptchaUrl = useCallback(() => {
      setCaptchaUrl(`${captchaUrlTemplate}${genRandomSeed()}`);
    }, [setCaptchaUrl, captchaUrlTemplate]);

    const _actionRequestOTP = useCallback(
      async (captcha = '') => {
        setIsRequestingOTP(true);
        const error = await actionRequestOTP(captcha);

        if (error) {
          actionResetCaptchaUrl();
          setCaptchaInput('');
        }
        setIsRequestingOTP(false);
      },
      [actionRequestOTP, actionResetCaptchaUrl],
    );

    return (
      <>
        <NavbarTitleCentered
          containerStyle={cStyles.bgw}
          leftButtonIcon={NAV_CLOSE}
          leftButtonAction={actionCloseForm}
          title={getLang('authPage.captchaVerification')}
        />
        <CaptchaVerification
          isRequestingOTP={isRequestingOTP}
          captchaInput={captchaInput}
          captchaUrl={captchaUrl}
          actionResetCaptchaUrl={actionResetCaptchaUrl}
          actionSetCaptchaInput={setCaptchaInput}
          actionRequestOTP={_actionRequestOTP}
        />
      </>
    );
  },
);

export default CaptchaInput;
