import React, { memo } from 'react';

import { Link, Text } from '@dkrm/ui-kit-basic';
import cStyles from '@dkrm/general-libs/theme/styles';
import { getLang } from 'app-libs/libs/getLang';
import colorsV2 from 'app-libs/constants/colors';

import { K_PRIVACY_POLICY, K_TERMS_CONDITIONS } from 'constants/routeConstants';

const RegisterTermsAndConditions: React.FC = memo(() => (
  <Text style={cStyles.tac}>
    <Text theme="caption3-black60">
      {getLang('authPage.registerForm.tncPrefix')}
    </Text>{' '}
    <Link to={K_TERMS_CONDITIONS} isUsingAnchor shouldOpenNewTab>
      <Text theme="caption3" style={{ color: colorsV2.C_PRIMARY_TEAL }}>
        {getLang('authPage.registerForm.tnc1')}
      </Text>
    </Link>{' '}
    <Text theme="caption3-black60">
      {getLang('authPage.registerForm.tncBridge')}
    </Text>{' '}
    <Link to={K_PRIVACY_POLICY} isUsingAnchor shouldOpenNewTab>
      <Text theme="caption3" style={{ color: colorsV2.C_PRIMARY_TEAL }}>
        {getLang('authPage.registerForm.tnc2')}
      </Text>
    </Link>
    <Text theme="caption3-black60">
      {getLang('authPage.registerForm.tncSuffix')}
    </Text>
  </Text>
));

export default RegisterTermsAndConditions;
