import React, { memo, useState, useCallback } from 'react';

import { ReflowView, View } from '@dkrm/ui-kit-basic';

import { Image } from '@dkrm/ui-kit-basic/v3';

import cStyles from '@dkrm/general-libs/theme/styles';

export const K_BANNER_WIDTH_PROPORTION = 375;
export const K_BANNER_HEIGHT_PROPORTION = 560;

interface LoginWallSwiperItemProps {
  imageUrl: string;
  name: string;
  shouldPreloadSmallerVersion: boolean;
}

// @note (Radit): use the smaller initial image width, it will be adjusted with onLayout
// somehow it will not show up regardless of the initial width
// so set the image width as small as possible, as it is not used and can reduce the bandwidth for the image.
const K_INITIAL_IMAGE_WIDTH = 20;

const LoginWallSwiperItem: React.FC<LoginWallSwiperItemProps> = memo(
  ({ imageUrl, name, shouldPreloadSmallerVersion }) => {
    const [width, setWidth] = useState(K_INITIAL_IMAGE_WIDTH);

    const onLayout = useCallback((event) => {
      const { layout } = event.nativeEvent;
      setWidth(layout.width);
    }, []);

    return (
      <View
        onLayout={onLayout}
        onMoveShouldSetResponder={() => true}
        style={cStyles.flex1}
      >
        <ReflowView
          widthProportion={K_BANNER_WIDTH_PROPORTION}
          heightProportion={K_BANNER_HEIGHT_PROPORTION}
        >
          <Image
            accessibilityLabel={name}
            shouldPreloadSmallerVersion={shouldPreloadSmallerVersion}
            source={imageUrl}
            width={width}
            style={cStyles.flex1}
            resizeMode="contain"
            additionalQuery={{
              fit: 'bounds',
              format: 'jpg',
            }}
          />
        </ReflowView>
      </View>
    );
  },
);

export default LoginWallSwiperItem;
