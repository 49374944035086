import React, { useCallback } from 'react';

import { Edit } from '@dkrm/icons';
import { Field } from '@dkrm/ui-kit-basic/Form';

import TextInput from 'app-libs/components/TextInput';
import { getLang } from 'app-libs/libs/getLang';

import { K_FIELD_EMAIL } from './constants';

interface EmailFieldProps {
  handleSubmit?: (
    values: { [K_FIELD_EMAIL]: string },
    formikActions: TYPEFIXME,
  ) => void;
  isDisabled?: boolean;
  setFieldValue: (field: string, value: string) => void;
  label?: string;
  onIconPress?: () => void;
}

const EmailField: React.FC<EmailFieldProps> = ({
  handleSubmit,
  isDisabled = false,
  setFieldValue,
  label = getLang('authPage.email'),
  onIconPress,
}) => {
  const handleChangeText = useCallback(
    (text: string) => {
      const trimmedText = text.trim();
      if (!/^\+?[0-9]*$/.test(trimmedText) || trimmedText === '') {
        setFieldValue(K_FIELD_EMAIL, trimmedText);
      }
    },
    [setFieldValue],
  );

  return (
    <Field name={K_FIELD_EMAIL}>
      {({ field, form: { submitCount, errors, touched } }: TYPEFIXME) => (
        <TextInput
          {...field}
          nativeID={K_FIELD_EMAIL}
          autoFocus
          errorMessage={
            (submitCount || touched[K_FIELD_EMAIL]) && errors[K_FIELD_EMAIL]
          }
          keyboardType="email-address"
          autoCapitalize="none"
          autoComplete="off"
          label={label}
          onBlur={null}
          onChangeText={handleChangeText}
          onSubmitEditing={handleSubmit}
          IconComponent={isDisabled ? Edit : null}
          onIconPress={onIconPress}
          placeholder={getLang('authPage.emailPlaceholder')}
          disabled={isDisabled}
        />
      )}
    </Field>
  );
};

export default EmailField;
