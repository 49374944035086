import React, { memo } from 'react';

import cStyles from '@dkrm/general-libs/theme/styles';
import { ScrollView, Text, View } from '@dkrm/ui-kit-basic';
import { enhanceForm } from '@dkrm/ui-kit-basic/Form';

import { useAuth } from 'app-libs/hook_modules/auth';
import { useVisibility } from 'app-libs/hook_modules/visibility';
import { getLang } from 'app-libs/libs/getLang';

import CTAButton from '../components/CTAButton';
import FormErrorMessage from '../components/FormErrorMessage';
import LoginMethodModal from '../components/LoginMethodModal';
import OtherMethod from '../components/OtherMethod';
import PhoneNumberField from '../components/PhoneNumberField';
import TnCSection from '../components/TnCSection';
import { phoneNumberValidator } from '../components/validators';

import { K_FIELD_PHONE_NUMBER } from './constants';
import { FormValues } from './types';

interface LoginOrRegisterProps {
  handleSubmit: (values: FormValues, formikActions: TYPEFIXME) => void;
  isSubmitting?: boolean;
  formErrorMessage?: string | null;
  setFieldValue: (field: string, value: string) => void;
}

const LoginOrRegister: React.FC<LoginOrRegisterProps> = memo((props) => {
  const { handleSubmit, isSubmitting, formErrorMessage, setFieldValue } = props;
  const [isModalVisible, showModal, hideModal] = useVisibility(false);
  const { isLoggedIn } = useAuth();

  return (
    <>
      <Text theme="h4-black80" style={{ marginTop: -60, zIndex: 9999 }}>
        {getLang('authPage.loginOrRegisterHeader')}
      </Text>
      <ScrollView
        contentContainerStyle={[cStyles.pageMaxWidth, { paddingTop: 20 }]}
      >
        <FormErrorMessage errorMessage={formErrorMessage} />
        <PhoneNumberField
          isDisabled={isLoggedIn}
          setFieldValue={setFieldValue}
          handleSubmit={handleSubmit}
          isAutoFocus={false}
        />
        <View style={{ paddingVertical: 10 }} />
        <CTAButton
          title={getLang('authPage.loginOrRegister')}
          onPress={handleSubmit}
          isDisabled={isSubmitting}
        />
        <OtherMethod onPress={showModal} />
        <TnCSection />
        {isModalVisible && (
          <LoginMethodModal onCancel={hideModal} shouldShowEmail />
        )}
      </ScrollView>
    </>
  );
});

const formConfig = {
  validator: {
    [K_FIELD_PHONE_NUMBER]: phoneNumberValidator,
  },
};

export default enhanceForm(formConfig)(LoginOrRegister);
