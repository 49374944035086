const K_INDONESIAN_PHONE_CODE = '+62';

export const internationalizeIndonesianPhoneNumber = (phoneNumber) => {
  if (
    phoneNumber.startsWith(K_INDONESIAN_PHONE_CODE) ||
    phoneNumber.startsWith('+')
  ) {
    return phoneNumber;
  }

  if (phoneNumber.startsWith(K_INDONESIAN_PHONE_CODE.slice(1))) {
    return `+${phoneNumber}`;
  }

  if (phoneNumber.startsWith('0')) {
    return `${K_INDONESIAN_PHONE_CODE}${phoneNumber.slice(1)}`;
  }

  return `${K_INDONESIAN_PHONE_CODE}${phoneNumber}`;
};

export const getCensoredPhoneNumber = (
  phoneNumber,
  uncensoredLastDigits = 3,
  censorChar = '*',
) => {
  if (uncensoredLastDigits < 0) {
    throw RangeError('uncensoredLastDigits only accepts positive integer');
  }

  let censoredLength = phoneNumber.length - uncensoredLastDigits;

  if (phoneNumber.startsWith(K_INDONESIAN_PHONE_CODE)) {
    censoredLength -= K_INDONESIAN_PHONE_CODE.length;
  } else if (phoneNumber.startsWith(K_INDONESIAN_PHONE_CODE.slice(1))) {
    censoredLength -= K_INDONESIAN_PHONE_CODE.length - 1;
  } else if (phoneNumber.startsWith('0')) {
    censoredLength -= 1;
  }

  if (censoredLength <= 0) {
    return phoneNumber;
  }

  const censored = `${censorChar.repeat(censoredLength)}${phoneNumber.slice(
    -uncensoredLastDigits,
  )}`
    .match(/.{1,4}/g)
    .join('-');
  return `${K_INDONESIAN_PHONE_CODE}-${censored}`;
};
