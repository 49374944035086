import React, { memo, useCallback } from 'react';

import cStyles from '@dkrm/general-libs/theme/styles';
import { ScrollView, View } from '@dkrm/ui-kit-basic';
import { enhanceForm } from '@dkrm/ui-kit-basic/Form';
import { NAV_CLOSE } from '@dkrm/ui-kit-basic/Icon/constants';
import { goBack } from '@dkrm/ui-kit-basic/Router';

import NavbarTitleCentered from 'app-libs/components/NavbarImplementation/NavbarTitleCentered';
import { useAuth } from 'app-libs/hook_modules/auth';
import { useHistory } from 'app-libs/hook_modules/router';
import { useVisibility } from 'app-libs/hook_modules/visibility';
import { getLang } from 'app-libs/libs/getLang';

import CTAButton from '../components/CTAButton';
import ClaimVoucherSection from '../components/ClaimVoucherSection';
import FormErrorMessage from '../components/FormErrorMessage';
import LoginMethodModal from '../components/LoginMethodModal';
import OtherMethod from '../components/OtherMethod';
import PhoneNumberField from '../components/PhoneNumberField';
import TnCSection from '../components/TnCSection';
import { phoneNumberValidator } from '../components/validators';

import { K_FIELD_PHONE_NUMBER } from './constants';
import { FormValues } from './types';

interface LoginOrRegisterProps {
  handleSubmit: (values: FormValues, formikActions: TYPEFIXME) => void;
  isSubmitting?: boolean;
  formErrorMessage?: string | null;
  setFieldValue: (field: string, value: string) => void;
}

const LoginOrRegister: React.FC<LoginOrRegisterProps> = memo((props) => {
  const { handleSubmit, isSubmitting, formErrorMessage, setFieldValue } = props;
  const [isModalVisible, showModal, hideModal] = useVisibility(false);
  const { isLoggedIn } = useAuth();
  const history = useHistory();

  const actionCloseForm = useCallback(() => {
    goBack(history);
  }, [history]);

  return (
    <>
      <NavbarTitleCentered
        containerStyle={cStyles.bgw}
        leftButtonIcon={NAV_CLOSE}
        leftButtonAction={actionCloseForm}
        title={getLang('authPage.loginOrRegister')}
      />
      <ScrollView contentContainerStyle={[cStyles.pageMaxWidth, cStyles.paxl]}>
        <ClaimVoucherSection />
        <FormErrorMessage errorMessage={formErrorMessage} />
        <PhoneNumberField
          isDisabled={isLoggedIn}
          handleSubmit={handleSubmit}
          setFieldValue={setFieldValue}
        />
        <View style={{ paddingVertical: 10 }} />
        <CTAButton
          title={getLang('authPage.loginOrRegister')}
          onPress={handleSubmit}
          isDisabled={isSubmitting}
        />
        <OtherMethod onPress={showModal} />
        <TnCSection />
        {isModalVisible && (
          <LoginMethodModal onCancel={hideModal} shouldShowEmail />
        )}
      </ScrollView>
    </>
  );
});

const formConfig = {
  validator: {
    [K_FIELD_PHONE_NUMBER]: phoneNumberValidator,
  },
};

export default enhanceForm(formConfig)(LoginOrRegister);
