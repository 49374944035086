import React, { useCallback } from 'react';

import { View, StyleSheet, Text, HorizontalDivider } from '@dkrm/ui-kit-basic';
import { ButtonWithText } from '@dkrm/ui-kit-basic/v2';
import cStyles from '@dkrm/general-libs/theme/styles';
import colorsV2 from 'app-libs/constants/colors';

import { getLang } from 'app-libs/libs/getLang';
import { trackClickedLoginOrRegisterOtherMethodButton } from 'app-libs/trackers';

interface OtherMethodProps {
  onPress: () => void;
}
const OtherMethod: React.FC<OtherMethodProps> = ({ onPress }) => {
  const handlePress = useCallback(() => {
    trackClickedLoginOrRegisterOtherMethodButton();
    onPress();
  }, [onPress]);

  return (
    <>
      <View style={s.orContainer}>
        <HorizontalDivider width="32%" />
        <Text theme="body4-black30" style={s.orTextContainer}>
          {getLang('authPage.orLoginWith')}
        </Text>
        <HorizontalDivider width="32%" />
      </View>
      <ButtonWithText
        title={getLang('authPage.otherMethod')}
        theme="black"
        themeType="secondary"
        onPress={handlePress}
        textStyle={s.buttonText}
        buttonStyle={s.buttonContainer}
      />
    </>
  );
};

const s = StyleSheet.create({
  orContainer: {
    ...cStyles.flexDirRow,
    ...cStyles.flexMiddle,
    ...cStyles.mvxl,
  },
  orTextContainer: {
    ...cStyles.tac,
    width: '36%',
  },
  buttonContainer: {
    ...cStyles.brm,
    borderColor: colorsV2.C_LIGHT_N030,
  },
  buttonText: {
    color: colorsV2.C_BLACK_80,
  },
});

export default OtherMethod;
