import { getLang } from 'app-libs/libs/getLang';

export const K_FIELD_EMAIL = 'email';
export const K_FIELD_PHONE_NUMBER = 'phoneNumber';
export const K_FIELD_PASSWORD = 'password';
export const K_FIELD_NAME = 'name';

export const K_STATE_PHONE_NUMBER_LOGIN_OR_REGISTER =
  'phone-number-login-or-register';

export const K_STATE_PHONE_NUMBER_REGISTER = 'phone-number-register';

export const K_LABEL_NAME = getLang('authPage.fullName');
export const K_LABEL_PHONE_NUMBER = getLang('authPage.phoneNumber');
export const K_LABEL_EMAIL = getLang(
  'authPage.phoneNumber.registerForm.emailLabel',
);
