import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import cStyles from '@dkrm/general-libs/theme/styles';
import {
  StyleSheet,
  Text,
  TouchableWithoutFeedback,
  View,
} from '@dkrm/ui-kit-basic';
import { NAV_CLOSE } from '@dkrm/ui-kit-basic/Icon/constants';
import { actionRouteActionOpenURL } from '@dkrm/ui-kit-basic/Router';
import { tStyles } from '@dkrm/ui-kit-basic/typography';

import NavbarTitleCentered from 'app-libs/components/NavbarImplementation/NavbarTitleCentered';
import { K_POSITION_BOTTOM_CENTER } from 'app-libs/components/Notification/BasePopup/constants';
import OTPInput from 'app-libs/components/OTPInput';
import { getCensoredPhoneNumber } from 'app-libs/etc/phoneNumberHelper';
import { useHistory } from 'app-libs/hook_modules/router';
import { useInterval } from 'app-libs/hook_modules/timer';
import { getLang } from 'app-libs/libs/getLang';
import { showSnackBar } from 'app-libs/redux_modules/flow_modules/notification';

import { K_ROUTE_OTP_KNOWLEDGE_CENTER } from 'constants/routeConstants';

import { K_AVAILABLE_PLATFORMS } from './constants';

const K_OTP_NUMBER_OF_DIGITS = 4;
const K_OTP_VALID_TIME = 30;

const VerifyPhoneNumberConfirmation = ({
  phoneNumber,
  platform,
  actionRequestOTP,
  errorMessage,
  actionVerifyOTP: _actionVerifyOTP,
  actionCloseForm,
}) => {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [otpCode, setOTPCode] = useState('');
  const [remainingTime, setRemainingTime] = useState(K_OTP_VALID_TIME);
  const dispatch = useDispatch();

  const platformName = K_AVAILABLE_PLATFORMS.find(
    (current) => current.key === platform,
  ).name;

  const isAbleToResendOTP = !isLoading && remainingTime <= 0;

  useEffect(() => {
    if (errorMessage) {
      dispatch(
        showSnackBar({
          message: errorMessage,
          position: K_POSITION_BOTTOM_CENTER,
          containerStyle: s.snackBarContainer,
          snackBarStyle: s.snackBar,
          textStyle: s.snackBarText,
        }),
      );
    }
  }, [errorMessage, dispatch]);

  useInterval(
    () => {
      if (remainingTime > 0) {
        setRemainingTime(remainingTime - 1);
      }
    },
    !isAbleToResendOTP ? 1000 : null,
  );

  const actionVerifyOTP = useCallback(
    async (code = otpCode) => {
      setIsLoading(true);
      const status = await _actionVerifyOTP(code);
      if (status !== 'ok') {
        setIsLoading(false);
      }
    },
    [_actionVerifyOTP, otpCode],
  );

  const actionChangeOTPInput = useCallback(
    (text) => {
      const isValidNumber = /^\+?[0-9]*$/.test(text);
      if (isValidNumber) {
        setOTPCode(text);
        if (text.length === K_OTP_NUMBER_OF_DIGITS) {
          actionVerifyOTP(text);
        }
      }
    },
    [actionVerifyOTP],
  );

  const actionResendOTP = () => {
    setRemainingTime(K_OTP_VALID_TIME);
    setOTPCode('');
    actionRequestOTP();
  };

  const censoredPhoneNumber = getCensoredPhoneNumber(phoneNumber);
  const resendTextColor = isAbleToResendOTP ? 'tosca4' : 'black30';
  const remainingTimeText = moment()
    .startOf('day')
    .seconds(remainingTime)
    .format('m:ss');

  const actionToOTPHelpCenter = () => {
    actionRouteActionOpenURL(K_ROUTE_OTP_KNOWLEDGE_CENTER, history, {
      shouldOpenNewTab: true,
    });
  };

  return (
    <>
      <NavbarTitleCentered
        containerStyle={cStyles.bgw}
        leftButtonIcon={NAV_CLOSE}
        leftButtonAction={actionCloseForm}
        title={getLang('authPage.otpVerification')}
      />
      <View style={s.container}>
        <Text theme="h4-black80" style={cStyles.mbxl}>
          {getLang('authPage.otpVerification.inputHeader')}
        </Text>
        <Text theme="body3-black60" style={{ marginBottom: 40 }}>
          {`Kami telah mengirimkan kode verifikasi via ${platformName} ke nomor `}
          <Text style={s.bold} theme="body3-black80">
            {censoredPhoneNumber}
          </Text>
        </Text>
        <OTPInput
          value={otpCode || ''}
          onChange={actionChangeOTPInput}
          hasError={!!errorMessage}
        />
        <TouchableWithoutFeedback
          onPress={isAbleToResendOTP ? actionResendOTP : null}
        >
          <View style={{ paddingTop: 32, marginHorizontal: 'auto' }}>
            <Text theme={`body3-${resendTextColor}`}>
              Kirim ulang OTP{' '}
              {(!!errorMessage || !isAbleToResendOTP) && (
                <Text theme="black60">{`(${remainingTimeText})`}</Text>
              )}
            </Text>
          </View>
        </TouchableWithoutFeedback>

        <TouchableWithoutFeedback onPress={actionToOTPHelpCenter}>
          <View style={s.needHelpContainer}>
            <Text theme="body3-tosca4">Butuh bantuan?</Text>
          </View>
        </TouchableWithoutFeedback>
      </View>
    </>
  );
};

VerifyPhoneNumberConfirmation.propTypes = {
  phoneNumber: PropTypes.string.isRequired,
  platform: PropTypes.string.isRequired,
  actionRequestOTP: PropTypes.func.isRequired,
  errorMessage: PropTypes.string.isRequired,
  actionVerifyOTP: PropTypes.func.isRequired,
  actionCloseForm: PropTypes.func.isRequired,
};

const s = StyleSheet.create({
  bold: {
    ...cStyles.fwb,
  },
  centerText: {
    ...cStyles.mtm,
    ...cStyles.tac,
  },
  container: {
    ...cStyles.flex1,
    ...cStyles.flexDirCol,
    ...cStyles.phxl,
    ...cStyles.mvxl,
    ...cStyles.mhm,
    paddingTop: 28,
    paddingBottom: 36,
    maxHeight: 700,
    justifyContent: 'space-between',
  },
  errorText: {
    ...cStyles.ptl,
    ...cStyles.pbxl,
    ...cStyles.tac,
  },
  snackBarContainer: {
    ...cStyles.pan,
    ...cStyles.bwn,
    ...cStyles.width100,
    ...cStyles.shadown,
    margin: 'auto',
    ...cStyles.mbxl,
    maxWidth: 340,
    zIndex: 10000,
    borderRadius: 8,
  },
  requestOTPContainer: {
    alignItems: 'center',
    ...cStyles.mhxxl,
    ...cStyles.mbxxl,
    marginTop: 32,
  },
  needHelpContainer: {
    alignItems: 'center',
    marginTop: 48,
    marginHorizontal: 'auto',
  },

  snackBar: {
    backgroundColor: '#fdeceb',
    ...cStyles.pvs,
    ...cStyles.bwn,
    ...cStyles.shadown,
    maxWidth: 340,
    borderRadius: 8,
    height: 52,
  },

  snackBarText: {
    ...cStyles.mhm,
    ...tStyles.body3,
    fontSize: 14,
    color: '#ef4237',
  },
});

export default VerifyPhoneNumberConfirmation;
