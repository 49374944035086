import React, { memo } from 'react';
import { Link, View, Text } from '@dkrm/ui-kit-basic';
import isEmpty from 'lodash/isEmpty';
import cStyles from '@dkrm/general-libs/theme/styles';

import { getLang } from 'app-libs/libs/getLang';
import { K_ROUTE_FORGOT_PASSWORD } from 'constants/routeConstants';
import { useQueries } from 'app-libs/hook_modules/router';
import { makeSerializedParameters } from 'app-libs/etc';

const ForgotPassword: React.FC = memo(() => {
  const queries = useQueries();
  const getLoginUrl = () => {
    if (!isEmpty(queries)) {
      return `${K_ROUTE_FORGOT_PASSWORD}?${makeSerializedParameters(queries)}`;
    }
    return K_ROUTE_FORGOT_PASSWORD;
  };

  return (
    <View style={[cStyles.mtm, cStyles.mbxl]}>
      <Link to={getLoginUrl()}>
        <Text theme="body3-tosca4">{getLang('authPage.forgotPassword')}</Text>
      </Link>
    </View>
  );
});

export default ForgotPassword;
