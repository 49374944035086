import React, { RefObject, createRef, memo, useEffect, useMemo } from 'react';

import cStyles from '@dkrm/general-libs/theme/styles';
import { StyleSheet, TextInput, View } from '@dkrm/ui-kit-basic';

import './index.scss';

export type OTPInputProps = {
  value: string;
  otpLength?: number;
  onChange: (value: string) => void;
  hasError?: boolean;
};

// Modified version from https://github.com/dominicarrojado/react-typescript-otp-input/blob/main/src/components/OTPInput.tsx
// changes are mostly styling
const OTPInput: React.FC<OTPInputProps> = memo(
  ({ value, onChange, otpLength = 4, hasError = false }) => {
    const inputRefs = useMemo(() => {
      const items: Array<RefObject<typeof TextInput>> = [];
      while (items.length < otpLength) items.push(createRef());
      return items;
    }, [otpLength]);

    useEffect(() => {
      const currentIndex = value.length;
      if (currentIndex < otpLength && inputRefs[currentIndex].current) {
        inputRefs[currentIndex].current.focus();
      }
    }, [inputRefs, otpLength, value]);

    const handleChange = (index: number, text: string) => {
      let newValue = '';
      const newValuesArray = value.split('');

      if (text === '' && index > 0) {
        // Delete character
        newValuesArray.splice(index, 1);
      } else {
        // Update or add character
        newValuesArray[index] = text;
      }

      newValue = newValuesArray.join('');
      onChange(newValue);
    };

    const handleKeyPress = (
      index: number,
      e: React.KeyboardEvent<HTMLInputElement>,
    ) => {
      const currInput = value[index] || '';
      if (e.key === 'Backspace' && index > 0 && currInput === '') {
        // Allow backspace to move to the previous input
        inputRefs[index - 1].current.focus();
      }
    };

    return (
      <View style={s.otpInputGroup}>
        {Array.from({ length: otpLength }, (_, index) => (
          <View
            style={hasError ? s.otpInputErrorContainer : s.otpInputContainer}
            key={index}
          >
            <TextInput
              key={index}
              ref={inputRefs[index]}
              value={value[index] || ''}
              maxLength={1}
              keyboardType="numeric"
              autoComplete="one-time-code"
              style={s.inputOTP}
              onChangeText={(text: string) => handleChange(index, text)}
              onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) =>
                handleKeyPress(index, e)
              }
              selectTextOnFocus
            />
          </View>
        ))}
      </View>
    );
  },
);

const s = StyleSheet.create({
  otpInputGroup: {
    ...cStyles.flexDirRow,
    margin: 'auto',
  },
  otpInputContainer: {
    backgroundColor: '#f7f8f8',
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#ebedee',
    width: 56,
    height: 56,
    borderRadius: 8,
    ...cStyles.mhl,
  },
  otpInputErrorContainer: {
    backgroundColor: '#fdeceb',
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#ef4237',
    width: 56,
    height: 56,
    borderRadius: 8,
    ...cStyles.mhl,
  },
  inputOTP: {
    ...cStyles.tac,
    borderBottomWidth: 0,
    borderStyle: 'none',
    fontSize: 21,
    lineHeight: 28,
  },
});

export default OTPInput;
