import React, { memo } from 'react';

import cStyles from '@dkrm/general-libs/theme/styles';
import { Refresh } from '@dkrm/icons';
import { StyleSheet, Text, TextInput, View } from '@dkrm/ui-kit-basic';
import { tStyles } from '@dkrm/ui-kit-basic/typography';
import { ButtonWithText, ButtonWrapper } from '@dkrm/ui-kit-basic/v2';
import { Image } from '@dkrm/ui-kit-basic/v3';

import colorsV2 from 'app-libs/constants/colors';

interface CaptchaVerificationProps {
  isRequestingOTP: boolean;
  captchaInput: string;
  captchaUrl: string;
  actionResetCaptchaUrl: () => void;
  actionRequestOTP: (captchaInput: string) => void;
  actionSetCaptchaInput: (captchaInput: string) => void;
}

const CaptchaVerification: React.FC<CaptchaVerificationProps> = memo(
  ({
    isRequestingOTP,
    captchaInput,
    captchaUrl,
    actionResetCaptchaUrl,
    actionRequestOTP,
    actionSetCaptchaInput,
  }) => {
    return (
      <>
        <View style={s.container}>
          <Text theme="body3-black60">
            Verifikasi dibutuhkan untuk melanjutkan. Harap isi karakter yang
            Kamu lihat pada gambar di bawah
          </Text>
          <View style={s.imageContainer}>
            <Image source={captchaUrl} width={210} height={78} />
          </View>
          <ButtonWrapper onPress={actionResetCaptchaUrl}>
            {() => (
              <View style={s.refreshButton}>
                <Refresh size={20} theme="tosca" />
                <Text theme="body3-tosca" style={s.refreshButtonText}>
                  Refresh Captcha
                </Text>
              </View>
            )}
          </ButtonWrapper>
          <TextInput
            label="Captcha"
            placeholder="Masukan Karakter"
            value={captchaInput}
            onChangeText={actionSetCaptchaInput}
            disabled={isRequestingOTP}
          />
          <ButtonWithText
            onPress={() => actionRequestOTP(captchaInput)}
            title={isRequestingOTP ? 'Lanjutkan...' : 'Lanjutkan'}
            theme="tosca"
            themeType="primary"
            buttonStyle={s.requestOTPButton}
            disabled={isRequestingOTP}
          />
        </View>
      </>
    );
  },
);

const s = StyleSheet.create({
  horizontalDivider: {
    ...cStyles.mtxxl,
    width: '100%',
  },
  imageContainer: {
    margin: 'auto',
    ...cStyles.mvxxl,
    ...cStyles.tac,
  },
  buttonContainer: {
    ...cStyles.mbxl,
  },
  button: {
    ...cStyles.pvxl,
    borderColor: colorsV2.C_BLACK_10,
  },
  buttonText: {
    ...tStyles.body2,
  },
  container: {
    ...cStyles.paxxl,
  },
  requestOTPButton: {
    backgroundColor: colorsV2.C_PRIMARY_TEAL,
    borderRadius: 8,
    ...cStyles.mvxxl,
  },
  refreshButton: {
    ...cStyles.flexDirRow,
    ...cStyles.paxs,
    borderRadius: 8,
    borderColor: colorsV2.C_PRIMARY_TEAL,
    width: 146,
    height: 28,
    borderStyle: 'solid',
    borderWidth: 1,
    margin: 'auto',
  },
  refreshButtonText: {
    ...cStyles.mhxs,
  },
});

export default CaptchaVerification;
