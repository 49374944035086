export const K_FIELD_EMAIL = 'email';
export const K_FIELD_PHONE_NUMBER = 'phoneNumber';
export const K_FIELD_PASSWORD = 'password';
export const K_FIELD_NAME = 'name';
export const K_FIELD_CONFIRM_PASSWORD = 'confirmPassword';

export const K_STATE_PHONE_NUMBER_REGISTER_OTP_VERIFICATION =
  'phone-number-register-otp-verification';
export const K_STATE_PHONE_NUMBER_LOGIN_OTP_VERIFICATION =
  'phone-number-login-otp-verification';
