/* eslint-disable camelcase, @typescript-eslint/camelcase */

import React, { memo, useCallback } from 'react';
import AppleLogin from 'react-apple-login';
import config from 'config';

import { View, Text, StyleSheet } from '@dkrm/ui-kit-basic';
import { ButtonWrapper as Button } from '@dkrm/ui-kit-basic/v2';

import { Apple } from '@dkrm/icons';

import cStyles from '@dkrm/general-libs/theme/styles';

import { getPlatform } from '@dkrm/general-libs/Utils/userAgentDetector';

import { isNewerVersion } from 'app-libs/etc';

const K_APPLE_LOGIN_RESPONSE_TYPE = 'code';
const K_APPLE_LOGIN_RESPONSE_MODE = 'form_post';
const K_APPLE_LOGIN_CLIENT_ID = 'com.dekoruma';
const K_APPLE_LOGIN_REDIRECT_URL = `${config.API_URL_SURTUR_V2}/apple-login-callback/`;
const K_APPLE_LOGIN_SCOPE = 'name email';

const K_APPLE_LOGIN_ICON_SIZE = 24;

const K_DEFAULT_LABEL = 'Masuk dengan Apple';

const K_APPLE_LOGIN_MINIMUM_APP_VERSION = '2.29';

interface AppleLoginButtonProps {
  label?: string;
}

const AppleLoginButton: React.FC<AppleLoginButtonProps> = memo(
  ({ label = K_DEFAULT_LABEL }) => {
    const platform = getPlatform();
    const appVersion = __SERVER__ ? '' : window?.__APP_VERSION__ || '';

    /* @note(dika) 8 Apr 2022
     * Show Apple login only on ios platform with app version > K_APPLE_LOGIN_MINIMUM_APP_VERSION
     */
    if (
      !isNewerVersion(K_APPLE_LOGIN_MINIMUM_APP_VERSION, appVersion) ||
      platform !== 'ios'
    ) {
      return null;
    }

    const renderLoginButton = useCallback(
      ({ onClick }) => {
        return (
          <Button style={s.button} onPress={onClick}>
            {() => (
              <View style={s.buttonContainer}>
                <Apple size={K_APPLE_LOGIN_ICON_SIZE} theme="white" />
                <Text style={s.buttonText} theme="body3-white">
                  {label}
                </Text>
              </View>
            )}
          </Button>
        );
      },
      [label],
    );

    return (
      <AppleLogin
        render={renderLoginButton}
        responseType={K_APPLE_LOGIN_RESPONSE_TYPE}
        responseMode={K_APPLE_LOGIN_RESPONSE_MODE}
        clientId={K_APPLE_LOGIN_CLIENT_ID}
        redirectURI={K_APPLE_LOGIN_REDIRECT_URL}
        scope={K_APPLE_LOGIN_SCOPE}
      />
    );
  },
);

const s = StyleSheet.create({
  button: {
    ...cStyles.mtxl,
    height: 48,
  },
  buttonContainer: {
    ...cStyles.flex1,
    ...cStyles.flexDirRow,
    ...cStyles.flexMiddle,
    ...cStyles.brs,
    backgroundColor: '#000000',
  },
  buttonText: cStyles.plxl,
});

export default AppleLoginButton;
