import React, { memo } from 'react';

import { Link } from '@dkrm/ui-kit-basic';
import { Text, View, cStyles } from '@dkrm/ui-kit-v2';

import { K_PRIVACY_POLICY, K_TERMS_CONDITIONS } from 'constants/routeConstants';

const TnCSection: React.FC = memo(() => {
  return (
    <View style={cStyles.mt12}>
      <Text theme="b4-neutral100" style={cStyles.tac}>
        Dengan masuk atau mendaftar ke Dekoruma, kamu menyetujui{' '}
        <Link to={K_TERMS_CONDITIONS} isUsingAnchor shouldOpenNewTab>
          <Text theme="h4-neutral100">Syarat dan Ketentuan</Text>
        </Link>{' '}
        serta{' '}
        <Link to={K_PRIVACY_POLICY} isUsingAnchor shouldOpenNewTab>
          <Text theme="h4-neutral100">Kebijakan Privasi</Text>
        </Link>{' '}
        Dekoruma
      </Text>
    </View>
  );
});

export default TnCSection;
