import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';

import cStyles from '@dkrm/general-libs/theme/styles';
import { ScrollView } from '@dkrm/ui-kit-basic';
import { enhanceForm } from '@dkrm/ui-kit-basic/Form';
import { NAV_CLOSE } from '@dkrm/ui-kit-basic/Icon/constants';
import { goBack } from '@dkrm/ui-kit-basic/Router';
import { StyleSheet } from '@dkrm/ui-kit-v2';

import NavbarTitleCentered from 'app-libs/components/NavbarImplementation/NavbarTitleCentered';
import { useHistory } from 'app-libs/hook_modules/router';
import { isLoggedIn as getIsLoggedIn } from 'app-libs/redux_modules/selectors/auth';

import CTAButton from '../components/CTAButton';
import ClaimVoucherSection from '../components/ClaimVoucherSection';
import EmailField from '../components/EmailField';
import ForgotPassword from '../components/ForgotPassword';
import FormErrorMessage from '../components/FormErrorMessage';
import PasswordField from '../components/PasswordField';
import { K_FIELD_EMAIL, K_FIELD_PASSWORD } from '../components/constants';
import {
  emailValidator,
  passwordLoginValidator,
} from '../components/validators';

import { FormValues } from './types';

interface LoginProps {
  handleSubmit(values: FormValues, formikActions: TYPEFIXME): void;
  isSubmitting: boolean;
  formErrorMessage: string | null;
  setFieldValue: (field: string, value: string) => void;
}

const Login: React.FC<LoginProps> = ({
  handleSubmit,
  isSubmitting,
  formErrorMessage,
  setFieldValue,
}) => {
  const isLoggedIn: boolean = useSelector(getIsLoggedIn);
  const history = useHistory();

  const actionCloseForm = useCallback(() => {
    goBack(history);
  }, [history]);

  return (
    <>
      <NavbarTitleCentered
        containerStyle={cStyles.bgw}
        leftButtonIcon={NAV_CLOSE}
        leftButtonAction={actionCloseForm}
        title="Masuk"
      />
      <ScrollView contentContainerStyle={s.container}>
        <ClaimVoucherSection />
        <FormErrorMessage errorMessage={formErrorMessage} />
        <EmailField setFieldValue={setFieldValue} isDisabled={isSubmitting} />
        <PasswordField handleSubmit={handleSubmit} isDisabled={isSubmitting} />
        <ForgotPassword />
        <CTAButton
          title={isLoggedIn ? 'Berhasil Masuk' : 'Masuk'}
          onPress={handleSubmit}
          isDisabled={isSubmitting || isLoggedIn}
        />
      </ScrollView>
    </>
  );
};

const s = StyleSheet.create({
  container: {
    ...cStyles.pageMaxWidth,
    ...cStyles.paxl,
  },
});

const formConfig = {
  validator: {
    [K_FIELD_EMAIL]: emailValidator,
    [K_FIELD_PASSWORD]: passwordLoginValidator,
  },
  enableReinitialize: true,
};

export default enhanceForm(formConfig)(Login);
