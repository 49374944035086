import React, { memo } from 'react';
import isEmpty from 'lodash/isEmpty';

import { StyleSheet } from '@dkrm/ui-kit-basic';
import { ButtonWithText } from '@dkrm/ui-kit-basic/v2';
import colorsV2 from 'app-libs/constants/colors';

import cStyles from '@dkrm/general-libs/theme/styles';

import { makeSerializedParameters } from 'app-libs/etc';
import { useQueries } from 'app-libs/hook_modules/router';

interface AbstractLoginButtonProps {
  label: string;
  url: string;
}

const AbstractLoginButton: React.FC<AbstractLoginButtonProps> = memo(
  ({ label, url }) => {
    const queries = useQueries();
    const getLoginUrl = () => {
      if (!isEmpty(queries)) {
        return `${url}?${makeSerializedParameters(queries)}`;
      }
      return url;
    };

    return (
      <ButtonWithText
        title={label}
        theme="black"
        themeType="secondary"
        to={getLoginUrl()}
        hoverableViewStyle={s.button}
        textStyle={s.buttonText}
        buttonStyle={s.buttonContainer}
      />
    );
  },
);

const s = StyleSheet.create({
  button: {
    ...cStyles.mtxl,
    height: 48,
  },
  buttonContainer: {
    ...cStyles.flex1,
    ...cStyles.flexDirRow,
    ...cStyles.flexMiddle,
    ...cStyles.brs,
    ...cStyles.bwam,
    ...cStyles.bgw,
    borderColor: colorsV2.C_LIGHT_N030,
  },
  buttonText: {
    ...cStyles.plxl,
    color: colorsV2.C_BLACK_80,
  },
});

export default AbstractLoginButton;
