import React from 'react';

import { Field } from '@dkrm/ui-kit-basic/Form';

import TextInput from 'app-libs/components/TextInput';
import { getLang } from 'app-libs/libs/getLang';

import { K_FIELD_NAME } from './constants';

interface NameFieldProps {
  isDisabled: boolean;
}

const NameField: React.FC<NameFieldProps> = ({ isDisabled }) => (
  <Field name={K_FIELD_NAME}>
    {({
      field,
      form: { submitCount, errors, touched, setFieldValue },
    }: TYPEFIXME) => (
      <TextInput
        {...field}
        nativeID={K_FIELD_NAME}
        autoFocus
        errorMessage={
          (submitCount || touched[K_FIELD_NAME]) && errors[K_FIELD_NAME]
        }
        name={K_FIELD_NAME}
        onChangeText={(text: string) => setFieldValue(K_FIELD_NAME, text)}
        label={getLang('authPage.fullName')}
        placeholder={getLang('authPage.fullNamePlaceholder')}
        autoComplete="off"
        onBlur={null}
        disabled={isDisabled}
      />
    )}
  </Field>
);

export default NameField;
