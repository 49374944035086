import React, { memo } from 'react';

import cStyles from '@dkrm/general-libs/theme/styles';
import { Colors, StyleSheet, Text, View } from '@dkrm/ui-kit-basic';

import { Voucher as IVoucher } from 'app-libs/components/campaign/types';

interface VoucherProps {
  voucher: IVoucher;
}

const Voucher: React.FC<VoucherProps> = memo(({ voucher }) => {
  return (
    <View style={s.voucherContainer}>
      <Text theme="h7-white" style={s.newUserVoucherLabel}>
        Khusus Pengguna Baru
      </Text>
      <Text theme="h5-black100">{voucher.name}</Text>
    </View>
  );
});

const s = StyleSheet.create({
  voucherContainer: {
    ...cStyles.bgw,
    ...cStyles.mtm,
    ...cStyles.paxl,
    boxShadow:
      '2px 2px 4px rgba(0, 0, 0, 0.05), 0 0 4px rgba(0, 0, 0, 0.1), 0 4px 4px rgba(0, 0, 0, 0.05)',
    borderRadius: 6,
  },
  newUserVoucherLabel: {
    ...cStyles.pvs,
    ...cStyles.phm,
    ...cStyles.brs,
    ...cStyles.mbs,
    backgroundColor: Colors.C_RED_500,
    width: 126,
  },
});

export default Voucher;
