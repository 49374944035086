import { UseMutationOptions, useMutation, useQuery } from 'react-query';
import { APIError } from 'types';

import { Voucher } from 'app-libs/components/campaign/types';

import {
  createWelcomeVoucher,
  getLoadProductVouchersQueryKey,
  loadProductVouchers,
  loadVoucherByVoucherCode,
} from './api';

const K_ONE_HOUR_IN_MILLISECONDS = 3600 * 1000;

export const useQueryLoadProductVouchers = (
  productUpc: string,
  brand: string,
  category: string | null,
) => {
  const { data, isError, isSuccess, isLoading, error } = useQuery(
    getLoadProductVouchersQueryKey(productUpc),
    () => loadProductVouchers(productUpc, brand, category),
    {
      enabled: !!productUpc,
      staleTime: K_ONE_HOUR_IN_MILLISECONDS,
      cacheTime: K_ONE_HOUR_IN_MILLISECONDS,
      refetchOnWindowFocus: false,
    },
  );

  return {
    vouchers: data,
    isError,
    isSuccess,
    isLoading,
    error,
  };
};

export const useQueryLoadVoucherByVoucherCode = (voucherCode: string) => {
  const { data, isError, isSuccess, isLoading, error } = useQuery(
    [`voucher-${voucherCode}`],
    () => loadVoucherByVoucherCode(voucherCode),
    {
      enabled: !!voucherCode,
      staleTime: 60 * K_ONE_HOUR_IN_MILLISECONDS,
      cacheTime: 120 * K_ONE_HOUR_IN_MILLISECONDS,
      refetchOnWindowFocus: false,
    },
  );

  return {
    voucher: data,
    isError,
    isSuccess,
    isLoading,
    error,
  };
};

export const useMutationCreateWelcomeVoucher = (
  options?: UseMutationOptions<Voucher, APIError>,
) => {
  return useMutation(createWelcomeVoucher, options);
};
