import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { actionRouteActionOpenURL } from '@dkrm/ui-kit-basic/Router';

import { getPhoneNumberRegisterAvailability } from 'app-libs/redux_modules/auth';
import { dontShowPromptOnError } from 'app-libs/redux_modules/flow_modules/notification';
import { internationalizeIndonesianPhoneNumber } from 'app-libs/etc/phoneNumberHelper';
import { useHistory, useQueries } from 'app-libs/hook_modules/router';
import { makeSerializedParameters } from 'app-libs/etc';

import { K_ROUTE_LOGIN_PHONE_NUMBER } from 'constants/routeConstants';

import {
  K_STATE_PHONE_NUMBER_REGISTER_OTP_VERIFICATION,
  K_STATE_PHONE_NUMBER_LOGIN_OTP_VERIFICATION,
} from '../components/constants';

export const usePhoneNumberLoginWallHandler = () => {
  const dispatch = useDispatch();
  const queries = useQueries();
  const history = useHistory();

  const [goblinErrorMessage, setGoblinErrorMessage] = useState<string | null>(
    null,
  );

  const getLoginPhoneNumberRedirectUrlProps = useCallback(
    (formState: string, phoneNumber: string) => {
      const urlState = {
        formState,
        phoneNumber,
      };
      return {
        pathname: K_ROUTE_LOGIN_PHONE_NUMBER,
        state: urlState,
        search: makeSerializedParameters(queries),
      };
    },
    [queries],
  );

  const handlePhoneNumberAvailability = useCallback(
    async (values, formikActions: TYPEFIXME) => {
      setGoblinErrorMessage(null);
      const { phoneNumber: initialPhoneNumber } = values;

      const phoneNumber = internationalizeIndonesianPhoneNumber(
        initialPhoneNumber.trim(),
      );

      const { result } = await dispatch(
        dontShowPromptOnError(getPhoneNumberRegisterAvailability(phoneNumber)),
      );

      let url;
      if (result) {
        url = getLoginPhoneNumberRedirectUrlProps(
          K_STATE_PHONE_NUMBER_REGISTER_OTP_VERIFICATION,
          phoneNumber,
        );
      } else {
        url = getLoginPhoneNumberRedirectUrlProps(
          K_STATE_PHONE_NUMBER_LOGIN_OTP_VERIFICATION,
          phoneNumber,
        );
      }
      actionRouteActionOpenURL(url, history);
      formikActions.setSubmitting(false);
    },
    [dispatch, history, getLoginPhoneNumberRedirectUrlProps],
  );

  return {
    goblinErrorMessage,
    handlePhoneNumberAvailability,
  };
};
