import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useSnackBar } from 'app-libs/hook_modules/snackBar';
import { useLoadAuthEffect } from 'app-libs/hook_modules/auth';
import { StyleSheet, View } from '@dkrm/ui-kit-basic';
import colors from '@dkrm/ui-kit-basic/colors';
import { actionRouteActionOpenURL } from '@dkrm/ui-kit-basic/Router';

import { useParams, useHistory } from 'app-libs/hook_modules/router';
import { passwordReset } from 'app-libs/redux_modules/auth';
import { dontShowPromptOnError } from 'app-libs/redux_modules/flow_modules/notification';
import { K_POSITION_TOP_CENTER } from 'app-libs/components/Notification/BasePopup/constants';
import { K_ROUTE_LOGIN } from 'constants/routeConstants';

import cStyles from '@dkrm/general-libs/theme/styles';

import { getLang } from 'app-libs/libs/getLang';
import ResetPasswordForm from './ResetPasswordForm';

const ResetPasswordPage: React.FC = () => {
  const dispatch = useDispatch();
  const { showSnackBar } = useSnackBar();
  const history = useHistory();
  const { uidb64, token } = useParams<{ uidb64: string; token: string }>();

  const actionResetPassword = useCallback(
    async (values: TYPEFIXME, formikActions: TYPEFIXME) => {
      const { password } = values;
      const { error } = await dispatch(
        dontShowPromptOnError(passwordReset(uidb64, token, password)),
      );

      if (error) {
        showSnackBar({
          message: error.response.body.error,
          backgroundColor: colors.C_PRIMARY_RED,
          position: K_POSITION_TOP_CENTER,
        });
      } else {
        showSnackBar({
          message: getLang('passwordReset.successMessage'),
          position: K_POSITION_TOP_CENTER,
        });

        actionRouteActionOpenURL(K_ROUTE_LOGIN, history);
      }
      formikActions.setSubmitting(false);
    },
    [uidb64, token, dispatch, showSnackBar, history],
  );

  useLoadAuthEffect();

  return (
    <View style={s.container}>
      <ResetPasswordForm onSubmit={actionResetPassword} />
    </View>
  );
};

const s = StyleSheet.create({
  container: {
    ...cStyles.pageMaxWidth,
    ...cStyles.pvn,
  },
});

export default ResetPasswordPage;
