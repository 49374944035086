import { gql } from 'graphql-request';

export const K_PRODUCT_DETAIL_VOUCHER_QUERY = gql`
  query GetProductVoucher(
    $productUpc: String!
    $brand: String
    $category: String
  ) {
    productVouchers(
      productUpc: $productUpc
      brand: $brand
      category: $category
    ) {
      bannerUrl
      code
      conditionType
      conditionValue
      customTnc
      dateCreated
      description
      discountValue
      discountType
      endTime
      includesAllProducts
      includedBrands
      includedCategories
      includedProducts
      isAvailable
      isClaimed
      isNewCustomerVoucher
      isOfflineOnly
      maximumApplicableDiscountAmount
      mustIncludeAddon
      name
      offerLocationCity
      offerLocationProvince
      partner
      partnerBrands
      period
      promoType
      publicBanner
      rank
      startTime
      termsAndConditions
      usableInAppOnly
      voucherIssuer
      voucherPaymentMethods
      voucherStoreLocations {
        code
      }
    }
  }
`;
